import { Box } from "@mui/material";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { ConfirmationList } from "./ConfirmationList/ConfirmationList";
import { ConfirmationStack } from "./ConfirmationStack/ConfirmationStack";
import { ConfirmationBaasStack } from "../../../pages/DeployBaas/DeployBaasConfirmation/ConfirmationBaasStack/ConfirmationBaasStack";

export const DeployConfirmation = () => {
  return (
    <>
      <Box>
        <DeployStepHeading
          title="Confirmation"
          description="Please check your request, as deployment is the next step"
        />
        <Box>
          <ConfirmationList />
        </Box>
        <Box sx={{ paddingTop: "20px" }}>
          {/* <ConfirmationBaasStack /> */}
          <ConfirmationStack />
        </Box>
      </Box>
    </>
  );
};
