import Api from "./api/axios-config";

export type TOrderService = {
  resourceGroup: {
    name: string;
    protocol: string;
    version: string;
    stack: string;
  };
  vms: any;
  enterpriseId: string;
  enterpriseWallet: string;
  organizationId: string;
  licenseId: string;
};

export type TOrderBaasService = {
  resourceGroup: {
    name: string;
    protocol: string;
    version: string;
    stack: string;
  };
  vms: any;
  enterpriseId: string;
  enterpriseWallet: string;
  organizationId: string;
  deployUUID: string;
};

const returnError = (error: any) => {
  if (error.response){
    const { statusCode, message } = error.response.data;
    return { status: statusCode, statusText: message, data: [] }
  }else{
    return { status: 500, statusText: error.code, data: [] }
  }
}
export class OrderService {
  async createOrder(order: TOrderService, tokenId: string) {
    try {
      const respOrder = await Api(tokenId).post(`/order/origin`, order);
      return respOrder;
    } catch (error) {
      return returnError(error);
    }
  }

  async createOrderBaas(order: TOrderBaasService, tokenId: string) {
    try {
      const respOrder = await Api(tokenId).post(`/order/baas/`, order);
      return respOrder;
    } catch (error) {
      return returnError(error);
    }
  }

  async createOrderBaasCorda(order: TOrderBaasService, tokenId: string) {
    try {
      const respOrder = await Api(tokenId).post(`/order/baas/corda`, order);
      return respOrder;
    } catch (error) {
      return returnError(error);
    }
  }

  async createOrderBaasBesu(order: TOrderBaasService, tokenId: string) {
    try {
      const respOrder = await Api(tokenId).post(`/order/baas/besu`, order);
      return respOrder;
    } catch (error) {
      return returnError(error);
    }
  }

  async createOrderBaasSubstrate(order: TOrderBaasService, tokenId: string) {
    try {
      const respOrder = await Api(tokenId).post(`/order/baas/substrate`, order);
      return respOrder;
    } catch (error) {
      return returnError(error);
    }
  }


  async fetchOrderStatus(idsArray: any, tokenId: string) {
    try {
      const respStatus = await Api(tokenId).post(
        `/order/task/status`,
        idsArray
      );
      return respStatus;
    } catch (error) {
      return returnError(error);
    }
  }
}
