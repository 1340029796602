import React, { useState, useEffect, useContext } from "react";
import { keycloak } from "../../utils/Keycloak";
import { useSnackbar } from "notistack";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Host } from "./Hosts";
import { MonitoringCard } from "./SingleMetric";
import { DeploymentCard } from "./Deployments";
import { BasicGraph } from "./BasicsGraph";
import { AlertSummaryCard } from "./AlertSummary";
import Header from "../../components/Platform/Header/Header";
import { IMockData7Page4 } from "../../type";
import { DeployHeader } from "../../components/Platform/DeployHeader/DeployHeader";
import { WizardContext } from "../../context/wizardContext";
import { MonitoringService } from "../../services/monitoring.service";
import { removeLocalStorage } from "../../generalLocalStorage";
import { Skeleton } from "@mui/material";

interface IMonitoring {
  cpuValue: string;
  memoryValue: string;
  diskValue: string;
}
interface IDataResourceGroup {
  resourceGroups: Array<any>;
  totalHosts: number;
  upHosts: number;
}

const fallBack = {
  alerts: [],
  cpuGraph: [[0], [0]],
  diskGraph: [[0], [0]],
  memoryGraph: [[0], [0]],
};

interface IMonitoringComponent {
  user: any;
  userName: string;
  tokenUser: string;
  thereIsOrganizationHeader: boolean;
}

export const Monitoring = ({
  user,
  userName,
  tokenUser,
  thereIsOrganizationHeader,
}: IMonitoringComponent) => {
  // const { tokenUser } = useContext<any>(WizardContext);
  const navigate = useNavigate();
  const monitoringService = new MonitoringService();
  const { enqueueSnackbar } = useSnackbar();

  const idEnt = localStorage.getItem("idEnterprise") || "";

  const [selectedSubitem, setSelectedSubitem] = useState<{
    dataKey: string;
    index: number;
  } | null>(null);
  const [dataValue, setDataValue] = useState<IMockData7Page4 | null>(null);
  const [isChartZooming, setIsChartZooming] = useState<boolean>(false);
  const [statusResource, setStatusResource] = useState<Array<boolean>>();

  const [dataValueChart, setDataValueChart] = useState<any>();
  const [dataResourceGroup, setDataResourceGroup] = useState<
    IDataResourceGroup | any
  >();
  const [gotData, setGotData] = useState<boolean>(false);
  const [currentUpTime, setCurrentUpTime] = useState<number>(0);
  const [idVmt, setIdvmt] = useState<string>("");
  const [newIdVms, setNewIdVms] = useState<Array<any>>();
  const [alertResource, setAlertResource] = useState<Array<any>>();
  const [loadingVm, setLoadingVm] = useState<boolean>(false);
  const [updateListNetwork, setUpdateListNetwork] = useState<boolean>(false);
  
  const [monitorinValue, setMonitorinValue] = useState<IMonitoring>({
    cpuValue: "",
    memoryValue: "",
    diskValue: "",
  });

  const [uptime, setUptime] = useState<any>();

  useEffect(()=>{
    const getVMUptime = async () => {
      const respRemoveNetwork = await monitoringService.getVmsUptimed(tokenUser);
      setUptime(respRemoveNetwork.data)
    }
    if(tokenUser){
      getVMUptime()
    }
  },[])

  const validateData = (dataValueChart: any) => {
    if (dataValueChart) {
      return Object.keys(dataValueChart).length !== 0;
    }
  };

  const getVmStatus = async (idVm?: any) => {
    setLoadingVm(true);
    try {
      if (idVm || idVmt) {
        const respGetVmStatus = await monitoringService.getVmBYId(
          tokenUser,
          idVm || idVmt
        );
        setGotData(true);
        if (respGetVmStatus.status === 200) {
          const { data } = respGetVmStatus;
          setLoadingVm(false);
          setAlertResource(data.alerts);
          if (idVmt) {
            if (validateData(data)) {
              setDataValueChart(data);
              setMonitorinValue({
                cpuValue: data["currentCpu"].toFixed(0),
                memoryValue: data["currentMemory"].toFixed(0),
                diskValue: data["currentDisk"].toFixed(0),
              });
            } else {
              setDataValueChart(data);
              setMonitorinValue({
                cpuValue: "",
                memoryValue: "",
                diskValue: "",
              });
              enqueueSnackbar("Access attempt to VM failed.", {
                variant: "error",
              });
            }
          }
        } else {
          enqueueSnackbar("Access attempt to VM failed.", {
            variant: "error",
          });
        }
      }
    } catch (error: any) {
      const { statusCode, message } = error?.response?.data;
      if ([403, 400, 404].includes(statusCode)) {
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
      if (statusCode === 401) {
        removeLocalStorage();
        keycloak.logout();
      }
    }
  };
  useEffect(() => {
    if (idVmt) {
      getVmStatus();
    }
  }, [idVmt]);

  const getDataResource = async () => {

    try {
      const respDataResource = await monitoringService.getResourceEnt(
        tokenUser
      );
      const { data } = respDataResource;


      if (respDataResource.status === 200) {
        setGotData(true);
        setDataResourceGroup(data);

      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 401) {
        keycloak.logout();
        removeLocalStorage();
      }
    }
  };
  useEffect(() => {
    if (tokenUser) {
      getDataResource();
    }
  }, []);

  useEffect(() => {
    if (tokenUser && updateListNetwork) {
      getDataResource();
      setUpdateListNetwork(false)
    }

  }, [updateListNetwork])

  useEffect(() => {
    if (selectedSubitem?.dataKey) {
      navigate(`./${selectedSubitem?.dataKey}/${selectedSubitem?.index}`);
    }
  }, [selectedSubitem]);

  return (
    <>
      <Header userName={userName} thereIsOrganizationHeader={thereIsOrganizationHeader} />
      <DeployHeader />
      <WizardContext.Provider
        value={{ alertResource, loadingVm, setIdvmt, statusResource }}
      >
        <Grid
          container
          rowSpacing={"20px"}
          sx={{ padding: { xs: "20px", xl: "70px 153px" } }}
        >
          <Grid container item spacing={"20px"} sx={{}}>
            {!isChartZooming && (
              <Grid item md={2}>
                {/* remover uphosts e totalHosts */}
                <Host
                  upHosts={uptime?.upHosts || 0}
                  totalHosts={uptime?.uptimes.length || 0}
                  loading={gotData}
                />
              </Grid>
            )}
            {!isChartZooming && (
              <Grid item md={3}>
                <Grid container item spacing={"20px"} sx={{}}>
                  <Grid item md={6}>
                    <MonitoringCard
                      name={"ALERTS"}
                      monitorinValue={monitorinValue}
                      loading={gotData}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MonitoringCard
                      name={"CPU (%)"}
                      monitorinValue={monitorinValue}
                      loading={gotData}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MonitoringCard
                      name={"MEMORY (%)"}
                      monitorinValue={monitorinValue}
                      loading={gotData}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MonitoringCard
                      name={"DISK (%)"}
                      monitorinValue={monitorinValue}
                      loading={gotData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item md={isChartZooming ? 12 : 7}>
              {!gotData 
              ?
              <Skeleton variant="rounded" sx={{borderRadius: '20px'}} height={250}/>
            :
              <BasicGraph
                dataValueChart={
                  validateData(dataValueChart) ? dataValueChart : fallBack
                }
                selectedSubitem={selectedSubitem}
                setSelectedSubitem={setSelectedSubitem}
                isChartZooming={isChartZooming}
                setIsChartZooming={setIsChartZooming}
              />
            }
            </Grid>
            {!isChartZooming && (
              <Grid item md={9}>
                <DeploymentCard
                  user={user}
                  uptime={uptime}
                  dataValue={dataResourceGroup}
                  currentUpTime={currentUpTime}
                  selectedSubitem={selectedSubitem}
                  setSelectedSubitem={setSelectedSubitem}
                  setUpdateListNetwork={setUpdateListNetwork}
                  tokenUser={tokenUser}
                />
              </Grid>
            )}
            {
              gotData
              ?
              !isChartZooming && (
                <Grid item md={3}>
                  <AlertSummaryCard
                    selectedSubitem={selectedSubitem}
                    dataValue={dataValue}
                  />
                </Grid>
              )
              :
              <Grid item md={3}>
                <Skeleton sx={{borderRadius: '30px'}} variant="rounded" height={422}/>
              </Grid>

            }
            
          </Grid>
        </Grid>
      </WizardContext.Provider>
    </>
  );
};
