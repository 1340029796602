import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Header from "../Header/Header";

import OrgsList from "../OrgsList/OrgsList";

import "./Home.css";

import LicenseList from "../LicensesList/LicensesList";
import MembersHome from "../MembersHome/MembersHome";
import Nodes from "../Nodes/Nodes";
import QuickFinancial from "../QuickFinancial/QuickFinancial";
import NetworkGraph from "../Network/network";
import { CreateOrganizationPage } from "../CreateOrganizationPage/createOrganizationPage";
import { Box, Button, Grid } from "@mui/material";
import { OrgFormContainer } from "../../moda-generic/orgFormContainer";
import { LicenseService } from "../../../services/license.service";
import { MemberService } from "../../../services/member.service";
import { UserService } from "../../../services/user.services";
import CircularProgress from "@mui/material/CircularProgress";
import { HomeHeader } from "./HomeHeader/HomeHeader";
import { EnterpriseService } from "../../../services/enterprise.service";
import { keycloak } from "../../../utils/Keycloak";
import { removeLocalStorage } from "../../../generalLocalStorage";
import { FinancialService } from "../../../services/financial.service";
import { MonitoringService } from "../../../services/monitoring.service";
import QuickMiniFinancial from "../QuickMiniFinancial/QuickMiniFinancial";
import { SkeletonHome } from "./skeletonHome";

export type THome = {
  userName: any;
  user: any;
  totalNumbers: number;
  tokenUser: string;
  thereIsOrganizationHeader: boolean;
};

const Home = ({
  user,
  userName,
  totalNumbers,
  tokenUser,
  thereIsOrganizationHeader,
}: THome) => {
  const enterpriseService = new EnterpriseService();
  const monitoringService = new MonitoringService();
  const [orgList, setOrgList] = useState<any>([]);
  const [orgListOriginal, setOrgListOriginal] = useState<any>([]);
  const [filterOrgs, setFilterOrgs] = useState<string>();
  const [successOrg, setSuccessOrg] = useState<boolean>(false);
  const [totalNumMember, setTotalNumMember] = useState<number>(totalNumbers);

  const [open, setOpen] = useState<boolean>(false);
  const [addInformationOrg, setAddInformationOrg] = useState<boolean>(true);
  const [licenseEnt, setLicenseEnt] = useState<Array<any>>([]);
  const [loadingLicense, setLoadingLicense] = useState<boolean>(true);
  const [idUserEnterprise, setIdUserEnterprise] = useState<string>("");
  const [enterpriseNetworks, setEnterpriseNetworks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tokenValidate, setTokenValidate] = useState<string>(tokenUser);
  const [dataTableQUickFinancial, setDataTableQUickFinancial] = useState<any>();
  const [totalHosts, setTotalHosts] = useState<number>(0);
  const [totalUpHosts, setTotalUpHosts] = useState<number>(0);
  const [loadingNetworkHome, setLoadingNetworkHome] = useState<boolean>(true);
  

  const financialService = new FinancialService();
  const licenseService = new LicenseService();
  const memberService = new MemberService();
  const userService = new UserService();
  const USER_ID = localStorage.getItem("idUser") || "";
  const idEnt = localStorage.getItem("idEnterprise") || "";

  useEffect(() => {
    const getDataQuickEnt = async () => {
      try {
        if (!idEnt) return;
        const respDataQuickFinancial =
          await financialService.getQuickFinancialEnt(tokenUser, idEnt);
        if (respDataQuickFinancial.status === 200) {
          setDataTableQUickFinancial(respDataQuickFinancial.data.reverse());
        }
      } catch (error) {
        console.error(error);
      }
    };
    getDataQuickEnt();
  }, []);

  const parentRef = useRef(null);
  const [parentDimensions, setParentDimensions] = useState({
    width: 745,
    height: 398,
  });

  useLayoutEffect(() => {
    const measureParent = () => {
      if (parentRef.current) {
        const { offsetWidth, offsetHeight } = parentRef.current;
        setParentDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    measureParent(); // Initial measurement

    // Re-measure if the window is resized
    window.addEventListener("resize", measureParent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", measureParent);
    };
  }, []);

  interface IDataResourcePercent {
    totalHosts: number;
    upHosts: number;
  }

  const getValuesResourcePercent = async () => {
    try {
      const getOnlineResouces = await monitoringService.getVmsUptimed(
        tokenUser
      );

      if (getOnlineResouces.status === 200) {
        setTotalUpHosts(getOnlineResouces?.data?.upHosts);
        setTotalHosts(getOnlineResouces?.data?.uptimes.length);
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getEnterpriseNets = async () => {
      setLoadingNetworkHome(true)
      const enterpriseNet = await enterpriseService.getEntepriseNetworks(
        idEnt,
        tokenUser
      );
      if (enterpriseNet.status == 200) {
        setEnterpriseNetworks(enterpriseNet.data);
      }
      setLoadingNetworkHome(false)
    };

    if (idEnt && tokenUser) {
      getEnterpriseNets();
    }
  }, []);

  const getLicenseEntrerpise = async (idEnterprise: string) => {
    try {
      setLoadingLicense(true);
      if (idEnterprise) {
        const respLicenseEnt = await licenseService.getEnterpriseLicense(
          idEnterprise,
          tokenValidate
        );
        if (respLicenseEnt.status === 401) {
          const logoutUser = async () => {
            localStorage.removeItem("owner");
            localStorage.removeItem("idEnterprise");
            localStorage.removeItem("idUser");
            localStorage.removeItem("enterpriseId");
            localStorage.removeItem("userWallet");
            localStorage.removeItem("entepriseServiceAuthId");
            localStorage.removeItem("idUser");
            localStorage.removeItem("ow");
            await keycloak.logout();
          };
          logoutUser();
        }
        if (respLicenseEnt.status === 200) {
          setLicenseEnt(respLicenseEnt.data);
        }
        setLoadingLicense(false);
      }
    } catch (error) {
      setLoadingLicense(false);
      console.error(error);
    }
  };

  const getInformationMember = async () => {
    const resInfoMember = await memberService.authUser(tokenUser);

    if ((resInfoMember.status === 201 || 200) && resInfoMember.data) {
      const { totalMembers } = resInfoMember.data;
      setTotalNumMember(totalMembers);
    }
  };

  useEffect(() => {
    if (tokenUser) {
      getValuesResourcePercent();
      setTokenValidate(tokenUser);
      getInformationMember();
    }
  }, [tokenUser]);

  const getUserEnterprise = async () => {
    try {
      if (USER_ID) {
        const resUserEnterprise = await memberService.getUserEnterprise(
          USER_ID,
          tokenValidate
        );
        if (resUserEnterprise.status === 200 && resUserEnterprise.data) {
          if (resUserEnterprise.data.length > 0) {
            const { id } = resUserEnterprise.data[0];
            setIdUserEnterprise(id);
            getLicenseEntrerpise(id);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      const { statusCode } = error?.response?.data;
      if ([401, 403].includes(statusCode)) {
        removeLocalStorage();
        await keycloak.logout();
      }
      console.error(error);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const respOrgList = await userService.getUserOrgList(USER_ID, tokenUser);
      if (respOrgList.status == 200) {
        if (respOrgList.data.length) {
          setOrgList(respOrgList.data);
          setOrgListOriginal(respOrgList.data);
        } else {
          setOrgList([]);
        }
      }
      if (respOrgList.status === 401) {
        const logoutUser = async () => {
          localStorage.removeItem("owner");
          localStorage.removeItem("idEnterprise");
          localStorage.removeItem("idUser");
          localStorage.removeItem("enterpriseId");
          localStorage.removeItem("userWallet");
          localStorage.removeItem("entepriseServiceAuthId");
          localStorage.removeItem("idUser");
          localStorage.removeItem("ow");
          await keycloak.logout();
        };
        logoutUser();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setAddInformationOrg(addInformationOrg);
  }, [addInformationOrg]);

  useEffect(() => {
    if (successOrg) {
      getData();
      setSuccessOrg(false);
    }
  }, [successOrg]);

  useEffect(() => {
    const orgFilter = orgList.filter((fil: any) => {
      if (filterOrgs) {
        return (
          fil.name?.toLowerCase().indexOf(`${filterOrgs.toLowerCase()}`) > -1
        );
      }
    });

    orgFilter.length && filterOrgs
      ? setOrgList(orgFilter)
      : setOrgList(orgListOriginal);
  }, [filterOrgs]);

  useEffect(() => {
    getData();
    getUserEnterprise();
  }, [USER_ID]);

  return (
    <>
      <Header
        userName={userName}
        thereIsOrganizationHeader={thereIsOrganizationHeader}
      />
      <HomeHeader />
      <OrgFormContainer
        user={user}
        title="Add Organization"
        open={open}
        setOpen={setOpen}
        setSuccessOrg={setSuccessOrg}
        successOrg={successOrg}
        ownerId={USER_ID}
        enterpriseId={idUserEnterprise}
        tokenUser={tokenUser}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "76vh",
          }}
        >
          <SkeletonHome />
        </Box>
      ) : (
        <>
          {orgList && orgList?.length < 1 && !loading ? (
            <>
              <Grid container item md={12} height="75vh" sx={{}}>
                <Grid
                  item
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CreateOrganizationPage open={open} setOpen={setOpen} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              item
              md={12}
              sx={{ marginTop: "73.65px", justifyItems: "space-around" }}
            >
              <Grid item md={4} sx={{ paddingLeft: "8%" }} alignSelf="stretch">
                <OrgsList
                  subId={USER_ID}
                  open={open}
                  setOpen={setOpen}
                  setFilterOrgs={setFilterOrgs}
                  orgList={orgList}
                  userName={userName}
                  loading={loading}
                />
              </Grid>
              <Grid item md={8} sx={{ paddingRight: "8%" }} alignSelf="center">
                <Grid item md={12}>
                  <Box className="title-card">
                    <h2>Enterprise View</h2>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  gap={3}
                  wrap="nowrap"
                  marginBottom="22px"
                >
                  <Grid item md={4}>
                    <Nodes
                      sizeResource={totalHosts}
                      totalUphosts={totalUpHosts}
                      totalHosts={totalHosts}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <MembersHome totalNumbers={totalNumMember} />
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <QuickMiniFinancial
                      dataTable={
                        dataTableQUickFinancial ? dataTableQUickFinancial : []
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item md={12} gap={3} wrap="nowrap">
                  <Grid item md={8}>
                    <div
                      ref={parentRef}
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <NetworkGraph
                        loadingNetwork={loadingNetworkHome}
                        height={parentDimensions.height}
                        width={parentDimensions.width}
                        data={enterpriseNetworks}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3.83}>
                    <LicenseList
                      licenseEnt={licenseEnt}
                      loadingLicense={loadingLicense}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Home;
