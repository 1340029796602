export const CloudLoad: React.FC = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_886_42154)">
      <path d="M34.0156 14.5088C31.9652 9.09622 26.7407 5.24609 20.6207 5.24609C14.5008 5.24609 9.2763 9.09174 7.22588 14.5043C3.37127 15.5206 0.532923 19.0439 0.555307 23.2253C0.582169 27.8992 4.20845 31.7001 8.77488 32.1075C8.9853 32.1254 9.16437 31.9597 9.16437 31.7493V29.0453C9.16437 28.8662 9.03007 28.714 8.85099 28.6916C7.64223 28.5394 6.50062 28.0111 5.62315 27.1471C4.5487 26.095 3.95775 24.6803 3.95775 23.1805C3.95775 21.927 4.36514 20.7451 5.13069 19.7602C5.87833 18.8021 6.9304 18.1082 8.08992 17.8038L9.78666 17.3561L10.4089 15.7131C10.794 14.6923 11.3312 13.7343 12.0072 12.8702C12.6743 12.0107 13.4667 11.2586 14.3531 10.6318C16.1931 9.33797 18.3599 8.65301 20.6207 8.65301C22.8816 8.65301 25.0484 9.33797 26.8884 10.6363C27.7793 11.263 28.5672 12.0152 29.2343 12.8747C29.9103 13.7388 30.4475 14.6968 30.8325 15.7175L31.4503 17.3561L33.1426 17.8038C35.5691 18.4485 37.2658 20.6556 37.2658 23.1761C37.2658 24.6803 36.6749 26.0905 35.6004 27.1426C34.7274 28.0021 33.5903 28.5349 32.3815 28.6871C32.2025 28.7095 32.0726 28.8617 32.0726 29.0408V31.7493C32.0726 31.9597 32.2562 32.1254 32.4666 32.1075C37.0196 31.6956 40.6369 27.8992 40.6683 23.2343C40.6951 19.0528 37.8613 15.5295 34.0156 14.5088V14.5088Z" fill="#CBD5E1" />
      <path d="M14.5636 27.3806C14.6442 25.8808 15.2665 24.484 16.332 23.414C17.4691 22.2724 19.0182 21.6322 20.6298 21.6322C22.2505 21.6322 23.7771 22.2635 24.9277 23.414C24.9903 23.4767 25.0485 23.5394 25.1112 23.6065L23.992 24.484C23.9387 24.5253 23.8981 24.5807 23.8749 24.644C23.8517 24.7073 23.8468 24.7759 23.8608 24.8418C23.8748 24.9078 23.9072 24.9684 23.9542 25.0168C24.0011 25.0651 24.0608 25.0993 24.1263 25.1152L28.5226 26.1897C28.7464 26.2434 28.9658 26.0733 28.9658 25.845L28.9882 21.3099C28.9882 21.0099 28.648 20.8398 28.4107 21.0278L27.372 21.8426C25.7335 19.9623 23.3249 18.7715 20.6343 18.7715C15.8261 18.7715 11.9044 22.5813 11.7074 27.3537C11.6984 27.5551 11.8596 27.7253 12.0655 27.7253H14.21C14.398 27.7208 14.5547 27.5731 14.5636 27.3806ZM29.1628 27.7208H27.0184C26.8303 27.7208 26.6736 27.8685 26.6602 28.061C26.5796 29.5608 25.9573 30.9576 24.8918 32.0276C23.7547 33.1692 22.2057 33.8094 20.594 33.8094C18.9734 33.8094 17.4468 33.1781 16.2962 32.0276C16.2335 31.9649 16.1753 31.9022 16.1126 31.8351L17.2319 30.9576C17.2852 30.9163 17.3258 30.8609 17.349 30.7976C17.3722 30.7343 17.3771 30.6657 17.363 30.5998C17.349 30.5338 17.3167 30.4732 17.2697 30.4248C17.2227 30.3764 17.1631 30.3423 17.0976 30.3263L12.7013 29.2519C12.4774 29.1982 12.258 29.3683 12.258 29.5966L12.2401 34.1362C12.2401 34.4361 12.5804 34.6063 12.8177 34.4182L13.8563 33.6034C15.4948 35.4837 17.9034 36.6746 20.594 36.6746C25.4022 36.6746 29.324 32.8647 29.5209 28.0924C29.5299 27.8909 29.3687 27.7208 29.1628 27.7208Z" fill="#CBD5E1" />
    </g>
    <defs>
      <clipPath id="clip0_886_42154">
        <rect width="40.113" height="40.113" fill="white" transform="translate(0.554688 0.857422)" />
      </clipPath>
    </defs>
  </svg>
);
