import React, { useState } from "react";
import axios from "axios";

import { mnemonicGenerate } from "@polkadot/util-crypto";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";

import { StyledEnterpriseWallet } from "../../Styles/EnterpriseWallet.styled";
import walletIcon from "~/assets/wallet_icon.png";
import walletWarningInfo from "~/assets/warning_wallet_info.png";
import copyButton from "~/assets/copyButton.png";
import { DeployAuthorization } from "../../../components/deployAuthorization";

import { ApiPromise, WsProvider } from "@polkadot/api";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import MultiLogo from "~/assets/logo.png";
import { Typography } from "@mui/material";

function EnterpriseWallet(props: {
  setFormInfo: (arg0: any) => void;
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
  };
}) {
  const mnemonic = mnemonicGenerate();
  const { enqueueSnackbar } = useSnackbar();
  const [walletName, setWalletName] = useState(
    props.formInfo.enterpriseWalletName
  );
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
  const [rawEnterpriseMnemonicWords, setRawEnterpriseMnemonicWords] =
    useState(mnemonic);
  const [enterpriseMnemonicWords, setEnterpriseMnemonicWords] = useState(
    rawEnterpriseMnemonicWords.split(" ")
  );
  const [enterpriseWalletAddress, setEnterpriseWalletAddress] = useState("");
  const [isEnterpriseWalletWasStoraged, setEnterpriseWalletWasStoraged] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);

  const onSubmitAuthorization = async (event?: any) => {
    setIsLoading(true)
    setLoadingAuthorization(true);
    const data = {
      ...props.formInfo,
      enterpriseWalletAddress: enterpriseWalletAddress,
    };

    props.setFormInfo(data);

    try {
      await waitReady();
      uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
    } catch (error) {
      console.log(error)
    } finally {
      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({provider});

      try {
        uiKeyring.getPair(data.userWalletAddress);
        uiKeyring.getPair(data.enterpriseWalletAddress);
        const userUUID = window.crypto.randomUUID();
        const entUUID = window.crypto.randomUUID();
        event.preventDefault(); // 👈️ prevent page refresh
    
        const ownerInfo = {
          id: userUUID,
          firstName: data.userFirstName,
          lastName: data.userLastName,
          phone: data.userPhone?.replace(/[./-]/g, ""),
          email: data.userEmail,
          cpf: data.userCPF?.replace(/[./-]/g, ""),
          zipCode: data.enterpriseZipCode,
          password: data.userPassword,
          jobTitle: data.userJobTitle,
          walletAddress: data.userWalletAddress,
          walletName: data.userWalletName
        };
        let ownerCreated;
        try {
          ownerCreated = await axios.post(
            `${process.env.REACT_APP_API_URL_ADDRESS}` + "/user",
            ownerInfo
            );
        } catch (error: any) {
            setLoadingAuthorization(false);
            enqueueSnackbar(error.message, {
              variant: "error",
            });
            await api.disconnect();
            setIsLoading(false)
            return;
        }

        const entepriseInfo = {
          id: entUUID,
          ownerId: ownerCreated?.data[0].id || '',
          name: data.enterpriseName,
          cnpj: data.enterpriseCNPJ?.replace(/[./-]/g, ""),
          zipCode: data.enterpriseZipCode?.replace(/[-]/g, ""),
          number: data.entepriseNumber,
          address: data.entepriseAddress,
          complement: data.entepriseAddress,
          country: data.entepriseCountry,
          state: data.entepriseState,
          city: data.entepriseCity,
          walletName: data.enterpriseWalletName,
          walletAddress: data.enterpriseWalletAddress
        };

        await axios.post(
          `${process.env.REACT_APP_API_URL_ADDRESS}/enterprise`,
          entepriseInfo
        );

        setLoadingAuthorization(false);
        setIsLoading(false)
        await api.disconnect();
        props.nextHandleClick();
      }
      catch (error: any) {
        console.log(error)      
        setLoadingAuthorization(false);
        enqueueSnackbar('General Error', {
          variant: 'error'
        });
        setIsLoading(false)
        await api.disconnect();
        return;
      }
    };
  }
  const listItems = enterpriseMnemonicWords.map((word) => (
    <li key={word.toString()}>
      <a>{word}</a>
    </li>
  ));

  const handleClick = async (event: any) => {
    const data = {
      ...props.formInfo,
      enterpriseWalletAddress: enterpriseWalletAddress,
    };
    // props.setFormInfo(data);

    
    const createWallet = async () => {
      try {
        await waitReady();
        uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
      } catch (error) {
        setEnterpriseWalletAddress("");
      } finally {
        const { pair, json } = uiKeyring.addUri(
          rawEnterpriseMnemonicWords,
          `${props.formInfo.enterpriseWalletPassword}`,
          { name: `${props.formInfo.enterpriseWalletName}` }
          );
          setEnterpriseWalletAddress(pair.address);
        }
      };
      
      createWallet();
      setEnterpriseWalletWasStoraged(event.target.checked);
  };

  return (
    <>
      <StyledEnterpriseWallet>
        <div className="container-owner-wallet">
          <div className="header">
            <h1 className="header-title">Enterprise Wallet Generation</h1>
            <h5 className="header-subtitle">
              Please write your mnemonic seed and keep it in a safe place. The
              mnemonic can be used to restore your wallet.
            </h5>
          </div>
          <div className="content">
            <div className="wallet-info">
              <div className="wallet-info-icon">
                <img src={walletIcon} alt="walletIcon" />
              </div>
              <div className="wallet-more-info">
                <div className="wallet-more-info-name">
                  {walletName == ""
                    ? "<unknown> wallet"
                    : `${walletName} wallet`}
                </div>
                <div className="wallet-address-button">
                  {enterpriseWalletAddress ? (
                    <div className="wallet-more-info-address">
                      {enterpriseWalletAddress}
                    </div>
                  ) : (
                    <div className="wallet-more-info-address">
                      Click in "I have saved my mnemonic..." to see your wallet
                      address
                    </div>
                  )}

                  <button
                    className="wallet-address-copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        enterpriseWalletAddress.toString()
                      );
                    }}
                  >
                    <img src={copyButton} alt="walletIcon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="mnemonic-info">
              <div className="mnemonic-title">
                GENERATED 12 WORD MNEMONIC SEED:
              </div>
              <div className="mnemonic-words">
                <ul>{listItems}</ul>
              </div>
              <button
                className="mnemonic-copy-button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    enterpriseMnemonicWords.toString().split(",").join(" ")
                  );
                }}
              >
                Copy mnemonic words
              </button>
            </div>
            <div className="warning-info">
              <div className="warning-icon">
                <img src={walletWarningInfo} alt="walletIcon" />
              </div>
              <div className="warning-text">
                Keep it carefully to not lose your assets.
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="footer-checkbox-mnemonic">
              <input
                className="checkbox-input"
                onClick={handleClick}
                type="checkbox"
                id="topping"
                name="topping"
                value="Paneer"
              />
              I have saved my mnemonic seed safely
            </div>
            <div className="footer-next-step">
              {isEnterpriseWalletWasStoraged && !!enterpriseWalletAddress ? (
                // <LoadingButton sx={{backgrondColor: 'red'}}
                // onClick={onSubmitAuthorization}
                // >
                //   <Typography sx={{
                //   fontFamily: 'Sen',
                //   fontSize: '14px',
                //   fontWeight: '600',
                //   lineHeight: '16.98px',
                //   textAlign: 'center',
                //   backgroundColor: 'red',
                //   }}>

                //     Next Loadingserter
                //   </Typography>
                //   {/* <img src={MultiLogo} alt="multiLogo"/> */}
                // </LoadingButton>
                // <button
                //   onClick={onSubmitAuthorization}
                //   className="footer-next-step-button"
                // >
                //   Next Step
                // </button>
                <button
                  onClick={onSubmitAuthorization}
                  className={`footer-next-step-button ${isLoading ? 'loading' : ''}`}
                >
                  {isLoading ? 'Creating...' : 'Create account'}
                </button>

              ) : (
                <button
                  disabled
                  onClick={onSubmitAuthorization}
                  className="footer-next-step-button-disabled"
                >
                  Create account
                </button>
              )}
            </div>
          </div>
        </div>
      </StyledEnterpriseWallet>
    </>
  );
}

export default EnterpriseWallet;
