import { ChangeEvent, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { Avatar, Backdrop, Box, Grid, Paper } from "@mui/material";
import Header from "../../components/Platform/Header/Header";
import { HomeHeader } from "../../components/Platform/Home/HomeHeader/HomeHeader";
import { fonts, colors } from "../../style/themes";
import { FormUserProfile } from "../../components/formUserProfile";
import { DataProfile } from "../../components/dataProfile";
import { CardSide } from "../../components/walletCard/cardSide";

export const Profile = ({
  user,
  userName,
  thereisOrganizationHeaders,
	tokenUser,
}: any) => {
  const navigate = useNavigate();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [successEditing, setSuccessEditing] = useState<boolean>(false);
	

  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    if (user) {
      setUserData({
				id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle,
        phone: user.phone,
        email: user.email,
        pass1: "********",
        pass2: "********",
        avatarPath: "",
      });
    }
  }, [user]);

  const goToWallet = () => {
    navigate("/profile/wallet/recovery");
  };

  return (
    <>
      <Header
        userName={userName}
        thereIsOrganizationHeader={thereisOrganizationHeaders}
      />
      <HomeHeader />
      {user ? (
        <>
          <Grid container item sx={{ padding: { xs: "20px", xl: "72px 152px" } }}>
            <Grid
              item
              md={12}
              sx={{
                height: "63px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: fonts.size[600],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight.secondary,
                color: colors.secondary[600],
                background: "#F1F3F4",
                border: "1px solid #CBD5E1",
                borderRadius: "30px 30px 0px 0px",
                marginBottom: "58px",
              }}
            >
              {`${userName}'s Profile`}
            </Grid>
            <Grid
              item
              md={2.2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: "25px 0 100px 0px",
              }}
            >
              <Avatar
                alt="profile"
                sx={{
                  bgcolor: "#1781E2",
                  height: "150px",
                  width: "150px",
                  fontSize: "60px",
                }}
              >
                {userName ? userName.charAt(0).toUpperCase() : "Error"}
              </Avatar>
            </Grid>
            <Grid
              item
              md={9.8}
              lg={9.8}
              display="flex"
              justifyContent="space-between"
            >
              <Paper
                sx={{
                  boxSizing: "border-box",
                  padding: "14px 25px",
                  background: "#F1F3F4",
                  boxShadow: "none",
                  border: "1px solid #CBD5E1",
                  borderRadius: "30px",
                  maxWidth: "707px",
                  height: "584px",
                  marginRight: "10px",
                }}
              >
                {!isEditing ? (
                  <DataProfile userData={userData} setIsEditing={setIsEditing} tokenUser={tokenUser}/>
                ) : (
                  <FormUserProfile
                    userData={userData}
                    setUserData={setUserData}
										setIsEditing={setIsEditing}
										setSuccessEditing={setSuccessEditing}
										tokenUser={tokenUser}
                    onCancel={() => navigate(-1)}
                  />
                )}
                {/* <Routes>
                  <Route
                    path="/"
                    element={<DataProfile userData={userData} />}
                  />
                  {testando}
                  <Route
                    path="/:edit"
                    element={
                      <FormUserProfile
                        userData={userData}
                        setUserData={setUserData}
                        onCancel={() => navigate(-1)}
                      />
                    }
                  />
                </Routes> */}
              </Paper>
              <Box>
                <CardSide
                  title="Wallet"
                  subTitle="You currently don´t have any wallet."
                  footer="Create your first wallet to get started"
                  walletAddress={user.walletAddress}
                  goToWallet={goToWallet}
                  dataWalletList={[{}]}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Backdrop open={user}></Backdrop>
        </>
      )}
    </>
  );
};
