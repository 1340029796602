export const privateBassFields = [
    "rpcPassword", "dbPassword", "trustStorePassword", "keyStorePassword", //CORDA
    "mnemonic", //Besu
    "mnemonic" //Substrate
]

export const nodeTemplates = [
    "regularNodes", "notaries", "doorman", "nodes", "vms" //Corda
]

export const protocolCheck = ["corda", "substrate", "besu", "origin"];