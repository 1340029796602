import { Grid } from "@mui/material";

export const HomeHeader = () => {
  return (
    <Grid
      container
      item
      md={12}
      sx={{
        backgroundColor: "#2E57A3",
        paddingLeft: "581px",
        paddingRight: "692px",
        height: "35.35px",
      }}
    >
    </Grid>
  );
};
