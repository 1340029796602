import { Box, Grid } from "@mui/material";
import { useContext } from "react";
import { DeployStepHeading } from "./DeployStepHeading";
import { SelectBox } from "./SelectBox";
import { WizardContext } from "../../../../../context/wizardContext";

export const DeployStack = () => {
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const handleSelectStack = (stack: string) => {
    setDeployData({ ...deployData, selectedStack: stack });
  };

  return (
    <Box>
      <DeployStepHeading
        title="Stack Selection"
        description="Select an appropriate stack size for your operation"
      />
      <Grid container item md={12} gap={1} flexWrap="nowrap">
        <Grid item md={5} lg={6}>
          <SelectBox
            heading="Standard"
            provisioner="For everyday operations 4 VM’s needed"
            selected={deployData.selectedStack === "Stack 1"}
            handleSelect={() => handleSelectStack("Stack 1")}
            enabled={true}
          />
        </Grid>
        <Grid item md={5} lg={6}>
          <SelectBox
            heading="Secure"
            provisioner="Redundante systems 10 VM’s needed"
            selected={deployData.selectedStack === "Stack 2"}
            handleSelect={() => {}}
            enabled={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
