import { useContext, useState } from "react";
import { WizardContext } from "../../context/wizardContext";
import { MemberService } from "../../services/member.service";
import { DeployAuthorization } from "../deployAuthorization";
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom';

import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { cryptoWaitReady } from "@polkadot/util-crypto";

import { ThemeProvider } from "@emotion/react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  styled,
  InputBase,
  DialogActions,
  Button,
  FormHelperText,
  DialogContent,
  DialogTitle,
  Dialog,
  Divider
} from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { customTheme } from "../../style";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseModal } from "~/assets";
import { textError } from "../../utils/textError";
import { useSelector } from "react-redux";

interface IAddMembers {
  email: string;
  permission: string;
}

export type TAddmembers = {
  setOpen: (value: boolean) => void,
  open: boolean;
  idMember?: string;
  idMemberVerifed?: any;
  loadingMember?: boolean;
  statusMember?: string;
  tokenUser: string;
  idOrg: string
};

export const InviteMembers = ({
  setOpen,
  open,
  idMember,
  loadingMember,
  statusMember,
  tokenUser,
  idOrg,
}: TAddmembers) => {

  const form = useForm<IAddMembers>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const [permissionUser, setPermissionUser] = useState<string>('');
  let owner: boolean;
  let memberEmail: string;
  const { user } = useContext<any>(WizardContext);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] = useState<boolean>(false);
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
  const memberService = new MemberService();

  const profileUser: any = useSelector(
		(state: any) => state.profileUser
	);

  memberEmail = localStorage.getItem('memberEmail') || ''

  try {
    owner = localStorage.getItem('owner') !== "";
  } catch (error) {
    owner = false;
  }

  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      textAlign: 'start',
      borderRadius: '10px',
      backgroundColor: '#FAFAFA',
      border: '1px solid #A2CBFE',
      '&:focus': {
        borderRadius: '10px',
      },
      ':after': {
        borderBottom: 'none',
        borderRight: '1px solid #A2CBFE',
        marginRight: '50px',
      }
    },
  }));

  useEffect(() => {
    !owner && profileUser !== 'admin'
      ? setPermissionUser('stakeholder')
      : setPermissionUser('owner');
  }, [owner])

  const onSubmit = () => {
    setOpenAuthorization(true);
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const onSubmitAuthorization = async () => {
    
    setLoadingAuthorization(true);
    const { email, permission } = form.getValues();
    // FAZER AQUI A INTEGRAÇAO DO ONCHAIN
    const callerUUID = localStorage.getItem("userId") as string;

    try {
      await cryptoWaitReady();
      uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
    } catch (error) {
      console.log(error)
    }

    const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
    const api = await ApiPromise.create({
      types: {
        UserRole: {
          _enum: ['Admin', 'StakeHolder']
        },
      },
      provider
    });

    let userPair: any
    try {
      userPair = uiKeyring.getPair(user.walletAddress);
    } catch (error) {
      enqueueSnackbar(textError, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setTimeout(() => {
        history('/profile/wallet/recovery')
      }, 5000);
      setLoadingAuthorization(false);
      return
    }

    try {
      userPair.unlock(passwordAuthorization);
    } catch (error) {
      setLoadingAuthorization(false);
      enqueueSnackbar('Invalid password', {
        variant: "error",
      });
      return
    }

    const memberUUID = window.crypto.randomUUID();

    const genesisHash = api.genesisHash;
    const runtimeVersion = api.runtimeVersion;

    let nonce = await api.rpc.system.accountNextIndex(user.walletAddress);

    let urole = api.createType('UserRole', 'StakeHolder')

    if (permission === "admin") {
      urole = api.createType('UserRole', 'Admin');
    }

    const txMemberAdd = api.tx.multiledgersIdentity
      .addMember(callerUUID, idOrg, memberUUID, urole).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

    const respInvite = await memberService.inviteMember({
      signedTx: txMemberAdd,
      id: memberUUID,
      email,
      permission,
      organizationId: idOrg
    }, tokenUser)
    if ([400, 401, 403, 500].includes(respInvite.status)) {
      enqueueSnackbar(respInvite.statusText,
        {
          variant: 'error'
        }
      )
    }
    if ([200, 201].includes(respInvite.status)) {
      enqueueSnackbar('Invite was sent sucessfully', {
        variant: "success",
      });
    }
    handleCloseAuthoraztion();
    setLoadingAuthorization(false);
    await api.disconnect();
    handleClose();
    localStorage.removeItem('memberEmail');
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (memberEmail) {
      form.setValue('email', memberEmail);
    }
  }, [memberEmail])

  return (
    <>
      <DeployAuthorization
        open={openAuthorization}
        loadingAuthorization={loadingAuthorization}
        onSubmitAuthorization={onSubmitAuthorization}
        setPasswordAuthorization={setPasswordAuthorization}
        handleCloseAuthorization={handleCloseAuthoraztion}
        title={"Invite member"}
        subText={"Invite member"}
        confirm="Sign"
        cancel="Cancel"
      />
      <Dialog
        aria-describedby="dialogo de confirmação"
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#F1F3F4',
            borderRadius: '18px',
          },
        }}
      >
        <DialogTitle
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Montserrat',
              fontWeight: '500',
              fontSize: '25px',
              color: '#5F6368',
            }
          }}>
          <Typography fontWeight={600} fontSize={25} alignSelf="center">
            Invite Member
          </Typography>
        </DialogTitle>
        <Box
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '20px',
            cursor: 'pointer',
            padding: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CloseModal />
        </Box>
        <Divider />
        <DialogContent>
          <form onSubmit={() => { }}>
            <ThemeProvider theme={customTheme}>
              <Grid container item md={12} spacing={2} sx={{ marginBottom: '24px' }}>
                <Grid item md={12} sm={12}>
                  <Controller
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormControl required variant="standard" fullWidth>
                        <InputLabel
                          error={!!form.formState.errors.email}
                          shrink
                          htmlFor="email"
                        >
                          Mail
                        </InputLabel>
                        <OutlinedInput
                          disabled={!!memberEmail}
                          error={!!form.formState.errors.email}
                          placeholder="type your email"
                          {...field}
                          type="string"
                          onChange={e => {
                            form.setValue('email', e.target.value);
                          }}
                        />

                        {!!form.formState.errors.email && (
                          <FormHelperText error>
                            {form?.formState?.errors?.email?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12} marginTop="20px">
                  <Controller
                    control={form.control}
                    name="permission"
                    render={({ field }) => (
                      <FormControl required variant="standard" fullWidth>
                        <InputLabel
                          sx={{ marginBottom: '-12px' }}
                          shrink
                          htmlFor="permission"
                          id="demo-simple-select-label"
                        >
                          Permission
                        </InputLabel>
                        <Select
                          disabled={profileUser === 'stakeholder' || statusMember === 'owner'}
                          {...field}
                          fullWidth
                          input={<BootstrapInput />}
                          labelId="demo-simple-select-label"
                          id="permission"
                          variant="standard"
                          onChange={e => {
                            form.setValue('permission', e.target.value)
                          }}
                          sx={{
                            ':before': {
                              borderRight: '1px solid #A2CBFE',
                              marginRight: '50px',
                              height: '100%',
                            },
                          }}
                        >
                          <MenuItem value={'admin'}>Admin</MenuItem>
                          <MenuItem value={'stakeholder'}>Stakeholder</MenuItem>
                        </Select>
                      </FormControl>

                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={12}
                display="flex"
                justifyContent="center"
                marginTop="20px"
                marginBottom="24px"
              >
                {!idMember &&
                  <Grid item md={10}>
                    <DialogActions>
                      <LoadingButton
                        loading={loadingMember}
                        fullWidth
                        onClick={() => form.handleSubmit(onSubmit)()}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        variant="contained"
                      // onClick={changeState}
                      >
                        <Typography
                          fontWeight="600"
                          fontSize="14px"
                          fontFamily="Montserrat"
                        >
                          Invite Member
                        </Typography>
                      </LoadingButton>
                    </DialogActions>
                  </Grid>
                }
              </Grid>
            </ThemeProvider>
          </form >
        </DialogContent>
      </Dialog>
    </>
  )
}