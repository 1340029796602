import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { Mnemonic } from '../Mnemonic';

interface IRecoveryWallet {
  wallet_address: string;
  tokenUser: string;
}

export const RecoveryWallet = ({ tokenUser, wallet_address }: IRecoveryWallet) => {
  const form = useForm<any>();
  const [recoveryNameWallet, setRecoveryNameWallet] = useState<string>('');
  const [passwordRecoveryInput, setPasswordRecoveryInput] =
    useState<string>('');
  const [confirmPassworRecoverydInput, setConfirmPassworRecoverydInput] =
    useState<string>('');
  const [validatePassword, setValidatePassword] = useState<any>();

  const [arrayMnemonic, setArrayMnemonic] = useState<Array<any>>([]);

  const setNewNameWallet = (e: any) => {
    setRecoveryNameWallet(e.target.value);
  };

  const setMnemonicWords = (e: any) => {
    const textWords = e.target.value.split(' ');
    setArrayMnemonic(textWords);
  };

  return (
    <>
      <form onSubmit={() => { }}>
        <Grid container item md={12} marginLeft="102px">
          <Grid item md={3.5} marginTop="56px" marginRight="177px">
            <Box>
              <Controller
                control={form.control}
                name="mnemonic"
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ marginBottom: '44px' }}
                  >
                    <InputLabel
                      sx={{
                        marginTop: '-30px',
                        color: '#636C7A',
                        fontSize: '1.25rem',
                        fontWeight: '700',
                        lineHeight: '32px'
                      }}
                      error={false}
                      shrink
                      htmlFor="mnemonic"
                    >
                      ENTER YOUR MNEMONIC
                    </InputLabel>
                    <TextField
                      placeholder="Mnemonic"
                      multiline
                      autoFocus
                      rows={2}
                      sx={{
                        backgroundColor: '#fff',
                        '& .MuiInputBase-input': {
                          fontFamily: 'Montserrat',
                          color: '#000',
                          padding: '0',
                          paddingLeft: '16px',
                          paddingRight: '16px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px !important',
                          border: '1px solid #CAD5E8 !important'
                        }
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      error={false}
                      {...field}
                      onChange={e => setMnemonicWords(e)}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Controller
                control={form.control}
                name="wallet"
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      sx={{
                        marginTop: '-30px',
                        fontSize: '1.25rem',
                        fontWeight: '700',
                        lineHeight: '32px',
                        color: "#636C7A"
                      }}
                      error={false}
                      shrink
                      htmlFor="wallet"
                    >
                      WALLET LABEL
                    </InputLabel>
                    <TextField
                      placeholder="label"
                      autoFocus
                      autoComplete='off'
                      sx={{
                        backgroundColor: '#fff',
                        '& .MuiInputBase-input': {
                          color: '#000',
                          fontFamily: 'Montserrat',
                          height: '44px',
                          padding: '0',
                          paddingLeft: '16px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px !important',
                          border: '1px solid #3A4049'
                        }
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      error={false}
                      {...field}
                      onChange={e => setNewNameWallet(e)}
                    />
                    <Box marginLeft="16px" marginBottom="42px">
                      <Typography color="#636C7A">
                        A descriptive name for your wallet
                      </Typography>
                    </Box>
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Controller
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      sx={{
                        marginTop: '-30px',
                        color: '#636C7A',
                        fontSize: '1.25rem',
                        fontWeight: '700',
                        lineHeight: '32px'
                      }}
                      error={false}
                      shrink
                      htmlFor="password"
                    >
                      PASSWORD
                    </InputLabel>
                    <TextField
                      placeholder="password"
                      type="password"
                      sx={{
                        backgroundColor: '#fff',
                        '& .MuiInputBase-input': {
                          color: '#000',
                          fontFamily: 'Montserrat',
                          height: '44px',
                          padding: '0',
                          paddingLeft: '16px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px !important',
                          border: '1px solid #3A4049'
                        }
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      error={validatePassword}
                      {...field}
                      onChange={e => setPasswordRecoveryInput(e.target.value)}
                    />
                    <Box marginLeft="16px" marginBottom="31px">
                      <Typography color="#636C7A">
                        A new password for this account
                      </Typography>
                    </Box>
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Controller
                control={form.control}
                name="confirm"
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      type="password"
                      placeholder="confirm password"
                      error={validatePassword}
                      sx={{
                        backgroundColor: '#fff',
                        '& .MuiInputBase-input': {
                          color: '#000',
                          fontFamily: 'Montserrat',
                          height: '44px',
                          padding: '0',
                          paddingLeft: '16px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px !important',
                          border: '1px solid #3A4049'
                        }
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      {...field}
                      onChange={e =>
                        setConfirmPassworRecoverydInput(e.target.value)
                      }
                    />
                    <Box marginLeft="16px">
                      <Typography color="#636C7A">
                        Repeat password for verification
                      </Typography>
                    </Box>
                    {validatePassword && (
                      <FormHelperText error sx={{ marginLeft: '18px' }}>
                        <Typography>passwords do not match</Typography>
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} alignSelf="flex-start" marginTop="25px">
            <Mnemonic
              tokenUser={tokenUser}
              words={arrayMnemonic}
              walletAddress={wallet_address}
              nameWallet={recoveryNameWallet}
              passwordInput={passwordRecoveryInput}
              confirmPasswordInput={confirmPassworRecoverydInput}
              setValidatePassword={setValidatePassword}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
