import React, { FC, useMemo, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  GridProps,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { fonts, colors } from "../../style/themes";

import { customTheme } from "../../style";

// import { B } from '../buttonContained'
import { ButtonOutlined } from "../buttonOutilined";
import InputLabel from "@mui/material/InputLabel";

import { IFormInformationUser } from "./IFormDataPage5";
import { SchemaFormProfile } from "./schemaFormProfile";
import { IinformationUser, IResponse } from "../../type";

import { UserService } from "../../services/user.services";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
// import { PAGE_FIVE } from '../../routes/book';

interface IFormUser extends GridProps {
  userData: IinformationUser | null;
  setUserData: Function;
  onCancel?: Function;
  tokenUser: string;
  setIsEditing: (value: boolean) => void;
  setSuccessEditing: (value: boolean) => void;
}

export const FormUserProfile: FC<IFormUser> = ({
  userData,
  onCancel,
  setIsEditing,
  setSuccessEditing,
  tokenUser,
  setUserData,
}) => {
  const form = useForm<IinformationUser>({
    resolver: yupResolver(SchemaFormProfile),
  });
  const [formFirstName, setformFirstName] = useState<string>("");
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

  const nagigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userService = new UserService();
  const blank = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    email: "",
    pass1: "",
    pass2: "",
  };
  const initValues = useMemo(() => {
    return userData ? userData : blank;
  }, [userData]);

  // useEffect(() => {
  // 	if (!userData) {
  // 		nagigate('/page-five')
  // 	}
  // }, [userData])

  // const {
  //   control,
  //   handleSubmit,
  //   reset,
  //   formState: { errors, isValid },
  // } = useForm<IFormInformationUser>({
  //   defaultValues: initValues,
  //   resolver: yupResolver(SchemaFormProfile),
  //   mode: "all",
  // });

  useEffect(() => {
    if (userData) {
      const fieldsToSet: any = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        jobTitle: userData.jobTitle,
        phone: userData.phone,
        email: userData.email,
        pass1: userData.pass1,
        pass2: userData.pass2,
      };

      Object.keys(fieldsToSet).forEach((field: any) => {
        form.setValue(field, fieldsToSet[field]);
      });
    }
  }, [userData]);

  const onSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const { firstName, lastName, jobTitle, phone } = form.getValues();

    if (!userData?.id) return;
    const payload = {
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      phone: phone,
    };

    if (!firstName || !lastName || !jobTitle || !phone) return;

    setLoadingInfo(true)
    const respDataUser = await userService.updateUser(
      userData.id,
      payload,
      tokenUser
    );
    setLoadingInfo(false)

    if (respDataUser.status === 200) {
      setIsEditing(false);
      setSuccessEditing(true);
      enqueueSnackbar("User edited successfully", {
        variant: "success",
      });
      window.location.reload();
    } else {
      enqueueSnackbar("error", {
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    onCancel && setIsEditing(false);
  };

  return (
    <>
      <form
        onSubmit={() => {}}
        style={{ display: "block", paddingTop: "20px" }}
      >
        <ThemeProvider theme={customTheme}>
          <Grid container item columnSpacing={3} rowSpacing={3}>
            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel
                      // error={!!form.formState.errors.firstName}
                      shrink
                      htmlFor="firstName"
                    >
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      placeholder="type your name"
                      // error={!!form.formState.errors.firstName}
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("firstName", e.target.value);
                      }}
                    />
                    {/* {!!form.formState.errors.firstName && (
                      <FormHelperText error>
                        error in the firstName
                      </FormHelperText>
                    )} */}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel
                      // error={!!form.formState.errors.lastName}
                      shrink
                      htmlFor="lastName"
                    >
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      placeholder="type your last name"
                      // error={!!form.formState.errors.lastName}
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("lastName", e.target.value);
                      }}
                    />
                    {/* {!!form.formState.errors.lastName && (
                      <FormHelperText error>
                        error in the lastName
                      </FormHelperText>
                    )} */}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="jobTitle"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel
                      // error={!!form.formState.errors.jobTitle}
                      shrink
                      htmlFor="jobTitle"
                    >
                      Job Title
                    </InputLabel>
                    <OutlinedInput
                      placeholder="type your job title"
                      // error={!!form.formState.errors.jobTitle}
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("jobTitle", e.target.value);
                      }}
                    />
                    {!!form.formState.errors.jobTitle && (
                      <FormHelperText error>
                        error in the jobTitle
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel
                      // error={!!form.formState.errors.phone}
                      shrink
                      htmlFor="phone"
                    >
                      Mobile
                    </InputLabel>
                    <OutlinedInput
                      placeholder="type your phone"
                      // error={!!form.formState.errors.phone}
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("phone", e.target.value);
                      }}
                    />
                    {/* {!!form.formState.errors.phone && ( */}
                    {/* <FormHelperText error>
                        error in the phone
                      </FormHelperText> */}
                    {/* )} */}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <Controller
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel
                      // error={!!form.formState.errors.email}
                      shrink
                      disabled
                      htmlFor="email"
                    >
                      Mail
                    </InputLabel>
                    <OutlinedInput
                      disabled
                      placeholder="type your last mail"
                      // error={!!form.formState.errors.email}
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("email", e.target.value);
                      }}
                    />
                    {/* {!!form.formState.errors.email && ( */}
                    {/* <FormHelperText error>
                        error in the email
                      </FormHelperText> */}
                    {/* )} */}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="pass1"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel disabled shrink htmlFor="pass1">
                      Passwoard
                    </InputLabel>
                    <OutlinedInput
                      placeholder="type your password"
                      disabled
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("pass1", e.target.value);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Controller
                control={form.control}
                name="pass2"
                render={({ field }) => (
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel disabled shrink htmlFor="pass2">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      placeholder="confirm you password"
                      disabled
                      {...field}
                      type="string"
                      onChange={(e) => {
                        form.setValue("pass2", e.target.value);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid
              container
              sx={{ justifyContent: "end", margin: "10px 0" }}
              columnSpacing={2}
              xs={12}
							paddingTop={7}
            >
              <Grid item>
                <ButtonOutlined
                  onClick={handleCancel}
                  sx={{
                    fontSize: fonts.size[200],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    padding: "8px 24px",
                    border: "1px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  Cancel
                </ButtonOutlined>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={loadingInfo}
                  type="submit"
                  variant="contained"
                  onClick={onSubmit}
                  sx={{
                    fontSize: fonts.size[200],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    padding: "8px 24px",
                    width: "169px",
                    borderRadius: "10px",
                    textTransform: "initial",
                  }}
                >
                  Save Info
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>
    </>
  );
};
