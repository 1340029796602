import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { WizardContext } from "../../../context/wizardContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  Box,
  Card,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";

import AttachFileIcon from "@material-ui/icons/AttachFile";

export const ProtocolSetupSubstrate = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2A2B2E", // Alterar para a cor de fundo desejada
      color: "#fff", // Alterar para a cor do texto desejada
      maxWidth: 220,
      borderRadius: "13px",
      backdropFilter: "blur(4.33707857131958px)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const clearInputs = () => {
    if (deployData.clearAllSub) {
      setDeployData({
        ...deployData,
        clearAllSub: false,
      });
    } else {
      setDeployData({
        ...deployData,
        clearAllSub: true,
      });
    }
  };

  useEffect(() => {
    if (deployData.mnemonic) {
      setDeployData({
        ...deployData,
        protocolSetup: true,
        machineSetup: false,
        buttonColor: "#1976d2",
      })
    } else {
      setDeployData({
        ...deployData,
        protocolSetup: false,
        machineSetup: false,
      });
      return;
    }

  }, [deployData.mnemonic])

  return (
    <Card
      sx={{
        height: "300px",
        boxShadow: "none",
        marginBottom: "9px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container item md={12} lg={12}  flexWrap="nowrap">
        <Grid item md={12} lg={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                marginRight: '20px',
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Mnemonic
            </Typography>
            <Box width="100%">
              <OutlinedInput
                placeholder="ex: juice escape curtain era snack regret sword category muffin"
                type="string"
                value={deployData.mnemonic}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    mnemonic: e.target.value
                  })
                }
                sx={{
                  width:"100%",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.mnemonic && (
                <FormHelperText error>{"typing mnemonic"}</FormHelperText>
              )} */}
              {/* {errorIp && (
                <FormHelperText error>{"IP is already in use"}</FormHelperText>
              )} */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item md={5.4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              onClick={clearInputs}
              fontFamily="Montserrat"
              fontWeight="600"
              color="#E14942"
              fontSize={"13px"}
              sx={{
                cursor: "pointer",
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Clear Vm Settings
            </Typography>

            <CustomTooltip
              title={"Type here to clear all vm settings"}
              placement="top"
              arrow
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: 20,
                  color: "#E14942",
                  paddingTop: "3px",
                  paddingLeft: "5px",
                }}
              />
            </CustomTooltip>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
