import { ThemeProvider } from "@emotion/react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputBase,
  styled,
  Button,
  Typography,
  DialogActions,
  FormHelperText
} from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from "react";
import { Controller, UseFormReturn } from 'react-hook-form';
import { customTheme } from "../../style";
import { ModalRemove } from "../modal-remove";
import { SelectChangeEvent } from '@mui/material';

const BootstrapInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    textAlign: 'start',
    borderRadius: '10px',
    backgroundColor: '#FAFAFA',
    border: '1px solid #A2CBFE',
    '&:focus': {
      borderRadius: '10px',
    },
    ':after': {
      borderBottom: 'none',
      borderRight: '1px solid #A2CBFE',
      marginRight: '50px',
    }
  },
}));

export type IAddOrganization = {
  id: string;
  name: string;
  email: string;
  sponsor: string;
  country: string;
  state: string;
  city: string;
};

interface ICountries {
  country: string;
  iso3: string
}
interface IStates {
  name: string;
  iso3: string
}


export type TAddOrganization = {
  form: UseFormReturn<IAddOrganization>;
  onSubmit: () => void;
  openEdit?: boolean;
  idOrg?: string
  countries: Array<ICountries>;
  states: Array<IStates>;
  setCountyOrg: (value: string) => void;
  countyOrg: string;
  setStateOrgSelect: (value: string) => void;
  stateOrgSelect: string;
  setCityOrgSelect: (value: string) => void;
  cityOrgSelect: string;
  dataCities: Array<any>;
  tokenUser: string;
  loadingOrg: boolean;
}

export const AddOrganization = ({
  form,
  onSubmit,
  openEdit,
  idOrg,
  countries,
  states,
  setCountyOrg,
  setStateOrgSelect,
  stateOrgSelect,
  countyOrg,
  setCityOrgSelect,
  cityOrgSelect,
  dataCities,
  tokenUser,
  loadingOrg,
}: TAddOrganization) => {
  const [open, setOpen] = useState<boolean>(false);

  const dataCountyOrg = (e: SelectChangeEvent) => {
    setCountyOrg(e.target.value)
  }

  const dataStateOrg = (e: SelectChangeEvent) => {
    setStateOrgSelect(e.target.value)
  }

  const dataCityOrg = (e: SelectChangeEvent) => {
    setCityOrgSelect(e.target.value)
  }

  return (
    <form onSubmit={() => { }}>
      <ThemeProvider theme={customTheme}>
        <Grid container item md={12} spacing={2} sx={{ marginBottom: '24px' }}>
          <Grid item md={12}>
            <Controller
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form?.formState.errors.name}
                    shrink
                    htmlFor="name"
                  >
                    Organization Name
                  </InputLabel>
                  <OutlinedInput
                    placeholder="type your name"
                    error={!!form?.formState.errors.name}
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('name', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.name && (
                    <FormHelperText error>
                      {form?.formState?.errors?.name?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form?.formState.errors.email}
                    shrink
                    htmlFor="email"
                  >
                    Organization Mail
                  </InputLabel>
                  <OutlinedInput
                    error={!!form?.formState.errors.email}
                    placeholder="type your email"
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('email', e.target.value);
                    }}
                  />

                  {!!form.formState.errors.email && (
                    <FormHelperText error>
                      {'type your E-mail'}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              control={form.control}
              name="sponsor"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form?.formState.errors.sponsor}
                    shrink
                    htmlFor="sponsor"
                  >
                    Sponsor
                  </InputLabel>
                  <OutlinedInput
                    error={!!form?.formState.errors.sponsor}
                    placeholder="type your sponsor"
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('sponsor', e.target.value);
                    }}
                  />

                  {!!form.formState.errors.sponsor && (
                    <FormHelperText error>
                      {'type your sponsor'}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <Controller
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="country"
                    id="demo-simple-select-label"
                  >
                    Country
                  </InputLabel>
                  <Select
                    {...field}
                    input={<BootstrapInput />}
                    labelId="demo-simple-select-label"
                    id="country"
                    variant="standard"
                    value={countyOrg}
                    onChange={e => dataCountyOrg(e)}
                    sx={{
                      ':before': {
                        borderRight: '1px solid #A2CBFE',
                        marginRight: '50px',
                        height: '100%',
                      },
                    }}
                  >
                    {countries &&
                      countries.map(({ iso3, country }) => (
                        <MenuItem
                          key={iso3}
                          value={country}>
                          {country}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <Controller
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="state"
                    id="demo-simple-select-label"
                  >
                    State
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="demo-simple-select-label"
                    id="state"
                    input={<BootstrapInput />}
                    variant="standard"
                    value={stateOrgSelect}
                    label="State"
                    onChange={e => dataStateOrg(e)}
                    sx={{
                      ':before': {
                        borderBottom: 'none',
                        borderRight: '1px solid #A2CBFE',
                        marginRight: '50px',
                        height: '100%',
                      },
                    }}
                  >
                    {states &&
                      states.map(({ iso3, name }) => (
                        <MenuItem
                          key={iso3}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      ))

                    }
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <Controller
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="city"
                    id="demo-simple-select-label"
                  >
                    City
                  </InputLabel>
                  <Select
                    {...field}
                    input={<BootstrapInput />}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={cityOrgSelect}
                    onChange={e => dataCityOrg(e)}
                    sx={{
                      ':before': {
                        borderBottom: 'none',
                        borderRight: '1px solid #A2CBFE',
                        marginRight: '50px',
                        height: '100%',
                      },
                    }}
                  >
                    {dataCities &&
                      dataCities.map((name) => (
                        <MenuItem value={name}>{name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          display="flex"
          justifyContent="center"
          marginTop="44px"
        // marginBottom="44px"
        >
          {!openEdit &&
            <Grid item md={10}>
              <DialogActions>
                <LoadingButton
                  loading={loadingOrg}
                  fullWidth
                  onClick={() => form.handleSubmit(onSubmit)()}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  variant="contained"
                // onClick={changeState}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Submit
                  </Typography>
                </LoadingButton>
              </DialogActions>
            </Grid>
          }
          {openEdit &&
            <Grid
              container
              padding="10px"
              md={12}
              sm={12}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid padding="10px" item md={4} sm={12} sx={{ padding: '98px 20x 0' }}>
                <Button
                  color="error"
                  onClick={() => setOpen(true)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Remove
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={4} sm={12}>
                <Button
                  sx={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                  onClick={() => form.handleSubmit(onSubmit)()}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Save
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          }
          <ModalRemove
            title="Remove Organization"
            setOpen={setOpen}
            open={open}
            content="Are you sure you want to delete this organization?"
            idOrg={idOrg}
            handleCloseRemove={() => { }}
            tokenUser={tokenUser}
          />
        </Grid>
      </ThemeProvider>
    </form>
  )
}