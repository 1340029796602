import {
  Box,
  Button,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { DeployBaasStepHeading } from "./DeployBaasStepHeading";
import axios from "axios";
import { AddSStandAlone } from "~/assets/icons/addStandAlone";
import { type ChangeEvent, useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../../context/wizardContext";
import CheckIcon from "@material-ui/icons/Check";
import { ClipBoard } from "~/assets/icons/clipBoard";
import { Delete } from "~/assets/icons/delete";
import { Deny } from "~/assets/icons/deny";
import { Check } from "~/assets/icons/check";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";

import gear from "../../../../../assets/icons/line.png";
import computer from "../../../../../assets/icons/solid.png";
import computerSuccess from "../../../../../assets/icons/computer-ok.png";
import { ProtocolSetupSubstrate } from "../../../../../components/Platform/ProtocolSetupSubstrate/ProtocolSetupSubstrate";
import { MachineSetupSubstrate } from "../../../../../components/machineSetupSubstrate/inde";

export type DeployVMSetupProps = {
  tokenUser: string;
};

type TStackType = {
  id: number;
  image: string;
  name: string;
};

export const DeployBaasVMSetup = ({ tokenUser }: DeployVMSetupProps) => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
  const { enqueueSnackbar } = useSnackbar();
  const [vmMessage, setVmMessage] = useState("");
  const [addLocked, setAddLocked] = useState(false);
  const [validateButonVm, setValidateButonVm] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [loadVmSetup, setLoadVmSetup] = useState(false);
  const [errorMnemonic, setErrorMnemonic] = useState(false);
  const [stepNode, setStepNode] = useState<number>(0);
  const [styleSetup, setStyleSetup] = useState<any>();
  const [isFirstGreen, setIsFirstGreen] = useState(false);
  const [isSecondEnabled, setIsSecondEnabled] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(1);
  const [machineView, setMachineView] = useState<boolean | null>(true);

  useEffect(() => {
    setDeployData({
      ...deployData,
      mnemonic:
        "juice escape curtain era snack regret sword category muffin boss impulse coil",
    });
  }, []);

  useEffect(() => {
    if (!deployData.valueNode || deployData.valueNode === "Unlimited") return;
    const numberNode = Number(deployData?.valueNode?.match(/\d+/)[0]);
    if (numberNode > 0) {
      setStepNode(numberNode);
    }
  }, [deployData.valueNode]);
  const [errorIp, setErrorIp] = useState(false);

  const [file, setFile] = useState<File | null>(null);

  const [vmIcon, setVmIcon] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (deployData.buttonColor === "#1976d2" && deployData.vmIp === "") {
      setDeployData({ ...deployData, buttonColor: "#7b8a93" });
    }
  }, []);

  useEffect(() => {
    const { vmIp, vmUsername, name, mnemonic } = deployData;
    if (vmIp && vmUsername && name && mnemonic && file) {
      setValidateButonVm(true);
    } else {
      setValidateButonVm(false);
    }
  }, [
    deployData.vmIp,
    deployData.vmUsername,
    deployData.name,
    deployData.mnemonic,
    file,
  ]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setDeployData({ ...deployData, fileSetup: e.target.files[0] });
    }
  };

  const validateNodeName = (nodeName: string) => {
    if (nodeName) {
      nodeName = nodeName
        ?.replace(" ", "")
        ?.replace(/[^\w\s]/gi, "")
        ?.replace("_", "");
      return nodeName;
    }
  };

  useEffect(() => {
    if (file || deployData.fileSetup) {
      setFile(file || deployData.fileSetup);
    }
  }, [file]);

  // const resetFile = () => {
  //   setFile(null);
  //   setInputKey(inputKey + 1);
  // }

  // const toggleBackground = () => {
  //   setBackgroundColor("#E5F7E6");
  //   setTimeout(() => {
  //     setBackgroundColor("#F5F5F5");
  //   }, 800);
  // };

  // const toggleBackgroundError = () => {
  //   setBackgroundColor("#F7E5E5");
  //   setTimeout(() => {
  //     setBackgroundColor("#F5F5F5");
  //   }, 800);
  // };

  // const updateCurrent1Vm = () => {
  //   if (deployData.currentVm === `${stepNode} - Node`) {
  //     setDeployData({ ...deployData, currentVm: `${stepNode} - lastNode` });
  //   }

  // }

  // const updateCurrentVm = () => {
  //   const clearFields = {
  //     vmIp: '',
  //     vmUsername: "",
  //     name: "",
  //     mnemonic: "",
  //   }

  //   if (stepNode === 1) {
  //     updateCurrent1Vm()
  //   }

  //   for (let i = 1; i <= stepNode; i++) {
  //     if (deployData.currentVm === `${i} - Node`) {
  //       if (i < stepNode) {
  //         setDeployData({
  //           ...deployData,
  //           currentVm: `${i + 1} - Node`,
  //           ...clearFields,
  //         });
  //       } else {
  //         setDeployData({ ...deployData, currentVm: `${i} - lastNode` });
  //       }
  //       // Se encontrou o node correspondente, saia do loop
  //       break;
  //     }
  //   }
  // };

  // const deleteStack = () => {
  //   setDeployData({
  //     ...deployData,
  //     currentVm: "1 - Node",
  //     vmIp: "",
  //     vmUsername: "",
  //     name: "",
  //     mnemonic: "",
  //     vms: [],
  //     buttonColor: "#7b8a93",
  //     fileSetup: null
  //   });
  //   resetFile();

  // };

  useEffect(() => {
    if (deployData.currentVm === `${stepNode} - lastNode`) {
      setDeployData({ ...deployData, buttonColor: "#1781E2" });
    }
  }, [deployData.currentVm]);

  // const checkMnemonic = (vms: any, mnemonicField: string) => {
  //   if (vms.length) {
  //     return vms.some((item: any) => item.mnemonic === mnemonicField);
  //   }
  // }

  // const testVm = () => {
  //   setErrorMnemonic(false)

  //   const thereisIp = deployData.vms.some((value: any) => {
  //     return value.externalIP === deployData.vmIp
  //   })

  //   setErrorIp(thereisIp);

  //   setAddLocked(true);
  //   setVmMessage("");
  //   setVmIcon(<></>);
  //   const varFormData = new FormData();

  //   varFormData.append("organizationId", deployData.orgId);
  //   varFormData.append("ip", deployData.vmIp);
  //   varFormData.append("username", deployData.vmUsername);

  //   if (file) {
  //     varFormData.append("file", file);
  //   }

  //   if (thereisIp) return;

  //   setLoadVmSetup(true);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL_ADDRESS}/vm/available/baas`,
  //       varFormData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer " + tokenUser,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       const vms = deployData.vms;

  //       if (res.status === 201 && vms.length < stepNode) {
  //         setLoadVmSetup(false);
  //         switch (deployData.currentVm) {
  //           case "1 - Node":
  //             vms.push({
  //               username: deployData.vmUsername,
  //               externalIP: deployData.vmIp,
  //               name: deployData.name,
  //               mnemonic: deployData.mnemonic
  //             });
  //             setDeployData({
  //               ...deployData,
  //               vms: vms,
  //             });
  //             break;
  //           case "2 - Node":
  //             vms.push({
  //               username: deployData.vmUsername,
  //               externalIP: deployData.vmIp,
  //               name: deployData.name,
  //               mnemonic: deployData.mnemonic
  //             });
  //             setDeployData({
  //               ...deployData,
  //               vms: vms,
  //             });
  //             break;
  //           case "3 - Node":
  //             vms.push({
  //               username: deployData.vmUsername,
  //               externalIP: deployData.vmIp,
  //               name: deployData.name,
  //               mnemonic: deployData.mnemonic
  //             });
  //             setDeployData({
  //               ...deployData,
  //               vms: vms,
  //             });
  //             break;
  //         }
  //         updateCurrentVm();
  //         toggleBackground();
  //         setVmMessage("Well done, VM validated!");
  //         setVmIcon(<Check />);
  //         setAddLocked(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoadVmSetup(false);
  //       toggleBackgroundError();
  //       setVmMessage("Invalid, please check your informations.");
  //       setVmIcon(<Deny />);
  //       setAddLocked(false);
  //     });
  // };

  // const validationButton = () => {
  //   return !validateButonVm || loadVmSetup || deployData.vms.length === stepNode
  // }

  useEffect(() => {
    if (deployData.nextStepProtocol) {
      setIsSecondEnabled(true);
      setIsFirstGreen(true);
    } else {
      setIsSecondEnabled(false);
      setIsFirstGreen(false);
    }
  }, [deployData.nextStepProtocol]);

  const getBorderStyle = (index: number, value: TStackType) => {
    if (index === 1) {
      return isSecondEnabled
        ? selectedId === value.id
          ? "2px solid #1781E2"
          : "1px solid #7B8A93"
        : "1px solid #D3D3D3";
    }
    return selectedId === value.id
      ? "2px solid #1781E2"
      : isFirstGreen
      ? "1px solid #1781E2"
      : !deployData.nextStepProtocol
      ? "1px solid #1781E2"
      : "1px solid #7B8A93";
  };

  const handleClick = (id: number) => {
    setSelectedId(id === selectedId ? id : id); // Toggle do estado selecionado
    id === 1 ? setMachineView(true) : setMachineView(false);
  };

  useEffect(() => {
    if (!deployData.nextStepProtocol) {
      setSelectedId(1);
      setMachineView(true);
    } else {
      setSelectedId(2);
      setMachineView(false);
    }
  }, [deployData.nextStepProtocol]);

  const StackType = [
    {
      id: 1,
      image: !deployData.nextStepProtocol ? computer : computerSuccess,
      name: "Machine Setup",
    },
    {
      id: 2,
      image: gear,
      name: "Protocol Setup",
    },
  ];

  return (
    <Box>
      <DeployBaasStepHeading
        title="VM Setup"
        description="Please import all your VM’s, one by one so we can proceed to the next phase"
      />
      <Box>
        <Grid container item md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              Importing Stack VM{deployData.currentVmEntSub}
            </Typography>
          </Grid>
          <Grid item md={1}>
            {deployData.selectedStack === "development" &&
            deployData.consesus === "QBFT" ? (
              <>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="600"
                  color="#5F6368"
                  fontSize={"13px"}
                  sx={{
                    paddingBottom: "10px",
                  }}
                >
                  {deployData.currentVmEntBesu} / 1
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="600"
                  color="#5F6368"
                  fontSize={"13px"}
                  sx={{
                    paddingBottom: "10px",
                  }}
                >
                  {deployData.currentVmEntSub} / 3
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          border: "1px solid #7B8A93",
          width: "100%",
          height: "22px",
          borderRadius: "5px",
        }}
      >
        <Grid container item md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                height: "15px",
                lineHeight: "15px",
                fontSize: "12px",
                textAlign: "left",
                color: "#7B8A93",
                paddingLeft: "4px",
                paddingTop: "2px",
                letterSpacing: "1px",
              }}
            >
              VM {deployData.currentVmEntSub}
            </Typography>
          </Grid>
          <Grid item md={1}>
            {loadVmSetup ? (
              <CircularProgress size={19} sx={{ fontSize: "12px" }} />
            ) : (
              <>{vmIcon}</>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          textAlign="right"
          sx={{ paddingTop: "10px" }}
        >
          {vmMessage}
        </Typography>
      </Box>

      <Grid container item marginTop="16px" md={12} columnGap={6} flexWrap="nowrap">
        {StackType.map((value: TStackType, index: number) => (
          <Grid item md={5.5}>
            <Box position="relative" overflow="hidden" style={styleSetup}>
              {index === 0 && isSecondEnabled && (
                <Box
                  position="absolute"
                  left="-20px"
                  top="-14px"
                  sx={{
                    width: "54px",
                    height: "39px",
                    transform: "rotate(-44.333deg)",
                    background: "rgba(23, 129, 226, 0.40)",
                  }}
                >
                  <CheckIcon
                    style={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: "bold",
                      marginLeft: "15px",
                      marginTop: "17px",
                      transform: "rotate(45.333deg)",
                    }}
                  />
                </Box>
              )}
              <Box
                onClick={() => handleClick(value.id)}
                sx={{
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  display: "flex",
                  // width: "191px",
                  height: "96px",
                  border: getBorderStyle(index, value),
                  justifyContent: "center",
                  alignItems: "center",
                  pointerEvents:
                    index === 1 && !isSecondEnabled ? "none" : "auto",
                  cursor:
                    index === 1 && !isSecondEnabled ? "not-allowed" : "pointer",
                  boxShadow: "0px 1px 10px 1px rgba(0, 0, 0, 0.10)",
                  // cursor: "pointer",
                  "&:hover": {
                    border: index === 1 ? "" : "2px solid #1781E2",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img src={value.image} width="50px" />
                  <Typography
                    textAlign="center"
                    color={
                      index === 1 || !deployData.nextStepProtocol
                        ? "#5F6368"
                        : "rgba(23, 129, 226, 0.60)"
                    }
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                    fontFamily="Montserrat"
                  >
                    {value.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          textAlign="right"
          marginBottom="14px"
          sx={{ paddingTop: "10px" }}
        >
          {vmMessage}
        </Typography>
      </Box>

      <Box>
        {machineView ? <MachineSetupSubstrate /> : <ProtocolSetupSubstrate />}
      </Box>
    </Box>
  );
};
