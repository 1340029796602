import React, { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import InputMask from 'react-input-mask';
import { useSnackbar } from "notistack";

function EnterpriseInfoForm(props: {
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  setFormInfo: (arg0: any) => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
};
})
{
  const { enqueueSnackbar } = useSnackbar();
  const [enterpriseName, setEnterpriseName] = useState(props.formInfo.enterpriseName);
  const [cnpj, setCnpj] = useState(props.formInfo.enterpriseCNPJ);
  const [enterpriseZipCode, setEnterpriseZipCode] = useState(props.formInfo.enterpriseZipCode);
  const [enterpriseCountry, setEnterpriseCountry] = useState(props.formInfo.entepriseCountry);
  const [enterpriseState, setEnterpriseState] = useState(props.formInfo.entepriseState);
  const [enterpriseCity, setEnterpriseCity] = useState(props.formInfo.entepriseCity);
  const [enterpriseAddress, setEnterpriseAddress] = useState(props.formInfo.entepriseAddress);
  const [enterpriseNumber, setEnterpriseNumber] = useState(props.formInfo.entepriseNumber);
  const [enterpriseComplement, setEnterpriseComplement] = useState(props.formInfo.entepriseComplement);
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);


  const addressInfo = useCallback(async () => {
    const pattern = /^\d{5}-\d{3}$/;
    if(pattern.test(enterpriseZipCode)){
      const response =  await axios.get(`https://viacep.com.br/ws/${enterpriseZipCode}/json/`);
      if (response.data.erro){
        setEnterpriseAddress("");
        setEnterpriseCity("");
        setEnterpriseState("");
        setEnterpriseCountry("");
      }else{
        setEnterpriseAddress(response.data.logradouro + ', ' + response.data.bairro);
        setEnterpriseCity(response.data.localidade);
        setEnterpriseState(response.data.uf);
        setEnterpriseCountry("Brazil");
      }
    }

  },[enterpriseZipCode])

  const anyEmptyField = () => {
    return (
      enterpriseName === "" ||
      cnpj === "" ||
      enterpriseZipCode === "" ||
      enterpriseAddress === "" ||
      enterpriseCountry === "" ||
      enterpriseState === "" ||
      enterpriseNumber === "" ||
      enterpriseCity === ""
    );
  };

  useEffect(() => {
    const isEmpty = !anyEmptyField();
    setIsAllDataFilled(isEmpty)
}, [enterpriseName
    ,cnpj
    ,enterpriseZipCode
    ,enterpriseCountry
    ,enterpriseState
    ,enterpriseCity
    ,enterpriseAddress
    ,enterpriseNumber
    ,enterpriseComplement]);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    if (anyEmptyField()) {
      alert("Please fill all fields");
      return;
    }
    const body = {
      "name": enterpriseName,
      "cnpj": cnpj?.replace(/[./-]/g, "")
    }
    const validEnterprise: { data: any } = await axios.post(
      `${process.env.REACT_APP_API_URL_ADDRESS}/enterprise/valid`,
      body
    );
    if (validEnterprise?.data?.name){
      enqueueSnackbar("This name is already in use", {
        variant: "error",
      });
      return;
    }
    if (validEnterprise?.data?.cnpj){
      enqueueSnackbar("This CNPJ is already in use", {
        variant: "error",
      });
      return;
    }

    event.preventDefault(); // 👈️ prevent page refresh

    const data = {
      ...props.formInfo,
      "enterpriseName": enterpriseName,
      "enterpriseCNPJ": cnpj?.replace(/[./-]/g, ""),
      "enterpriseZipCode": enterpriseZipCode?.replace(/[-]/g, ""),
      "entepriseAddress": enterpriseAddress,
      "entepriseNumber": enterpriseNumber,
      "entepriseComplement": enterpriseComplement,
      "entepriseCountry": enterpriseCountry,
      "entepriseState": enterpriseState,
      "entepriseCity": enterpriseCity,
    };

    props.setFormInfo(data);

    props.nextHandleClick();

  };

  const handlePreviousSubmit = async (event: { preventDefault: () => void; }) => {
    
    event.preventDefault(); // 👈️ prevent page refresh

    props.previousHandleClick();
  };

  return (
    <div className="content-enterprise-info">
    <h2 className='enterprise-header'>ENTERPRISE INFORMATION</h2>
    <form onSubmit={handleSubmit}>
      <div className="input-container">
        <div className="input-horizontal">
          <div className="input-initial">
            <label>ENTERPRISE NAME*</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseName(event.target.value)}
              value={enterpriseName}
            />
          </div>
          <div className="input-initial">
            <label>CNPJ*</label>
            <InputMask mask="99.999.999/9999-99"
              onChange={(event) => setCnpj(event.target.value)}
              value={cnpj}>
            </InputMask>
          </div>
        </div>
        <div className="input-horizontal">
          <div className="input-initial">
            <label>ZIP CODE*</label>
            <InputMask mask="99999-999"
              onChange={(event) => setEnterpriseZipCode(event.target.value)}
              onBlur={(event) => addressInfo()}
              value={enterpriseZipCode}>
            </InputMask>
            {/* <input
              type="number"
              onChange={(event) => setEnterpriseZipCode(event.target.value)}
              value={enterpriseZipCode}
            /> */}
          </div>
        <div className="input-initial">
            <label>ADDRESS*</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseAddress(event.target.value)}
              value={enterpriseAddress}
            />
          </div>
        </div>
        <div className="input-horizontal">
          <div className="input-initial">
            <label>NUMBER*</label>
            <input
              type="number"
              onChange={(event) => setEnterpriseNumber(event.target.value)}
              value={enterpriseNumber}
            />
          </div>
          <div className="input-initial">
            <label>COMPLEMENT</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseComplement(event.target.value)}
              value={enterpriseComplement}
            />
          </div>
        </div>
        <div className="input-horizontal">
          <div className="input-initial">
            <label>COUNTRY*</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseCountry(event.target.value)}
              value={enterpriseCountry}
            />
          </div>
          <div className="input-initial">
            <label>STATE*</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseState(event.target.value)}
              value={enterpriseState}
            />
          </div>
        </div>
        <div className="input-horizontal">
          <div className="input-initial">
            <label>CITY*</label>
            <input
              type="text"
              onChange={(event) => setEnterpriseCity(event.target.value)}
              value={enterpriseCity}
            />
          </div>
        </div>
        <div className="input-horizontal">
        <button className="button-create-enterprise" type="button" onClick={handlePreviousSubmit}
         style={{cursor: 'pointer'}}>
          Previous Step
        </button>
        {isAllDataFilled
        ? 
        <button className="button-create-enterprise-selected" type="button" onClick={handleSubmit}
        style={{cursor: 'pointer'}}
        >
          Next - Payments
        </button>
        :
        <button className="button-create-enterprise" type="button" disabled
          style={{backgroundColor: '#7B8A93', border: '0px', cursor: "not-allowed" }}>
          Next - Payment
        </button>
        }
        </div>
      </div>
    </form>
    </div>
  );
}

export default EnterpriseInfoForm;
