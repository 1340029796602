import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DeployStepHeading } from "./DeployStepHeading";
import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../../context/wizardContext";

import computer from "../../../../../assets/icons/solid.png";
import computerSuccess from "../../../../../assets/icons/computer-ok.png";
import gear from "../../../../../assets/icons/line.png";

import CheckIcon from "@material-ui/icons/Check";
import { MachineSetupOrigin } from "./MachineSetupOrigin";
import { ProtocolSetupOrigin } from "./ProtocolSetupOrigin";

export type DeployVMSetupProps = {
  tokenUser: string;
};

type TStackType = {
  id: number;
  image: string;
  name: string;
};

export const DeployVMSetup = ({ tokenUser }: DeployVMSetupProps) => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
  const [validateButonVm, setValidateButonVm] = useState(false);
  const [isFirstGreen, setIsFirstGreen] = useState(false);
  const [isSecondEnabled, setIsSecondEnabled] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(1);
  const [machineView, setMachineView] = useState<boolean | null>(true);
  const [styleSetup, setStyleSetup] = useState<any>();

  const [loadVmSetup, setLoadVmSetup] = useState(false);

  const [vmMessage, setVmMessage] = useState("");

  const [file, setFile] = useState<File | null>(null);

  const [vmIcon, setVmIcon] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (deployData.nextStepProtocol) {
      setIsSecondEnabled(true);
      setIsFirstGreen(true);
    } else {
      setIsSecondEnabled(false);
      setIsFirstGreen(false);
    }
  }, [deployData.nextStepProtocol]);

  useEffect(() => {
    if (file || deployData.fileSetup) {
      setFile(file || deployData.fileSetup);
    }
  }, [file]);

  useEffect(() => {
    const { vmIp, vmUsername } = deployData;
    if (vmIp && vmUsername && file) {
      setValidateButonVm(true);
    } else {
      setValidateButonVm(false);
    }
  }, [deployData.vmIp, deployData.vmUsername, file]);



  // useEffect(() => {
  //   if (deployData.currentVm === "4 - lastNode") {
  //     setDeployData({ ...deployData, buttonColor: "#1781E2" });
  //   }
  // }, [deployData.currentVm]);


  const handleClick = (id: number) => {
    setSelectedId(id === selectedId ? id : id); // Toggle do estado selecionado
    id === 1 ? setMachineView(true) : setMachineView(false);
  };

  useEffect(() => {
    if (!deployData.nextStepProtocol) {
      setSelectedId(1);
      setMachineView(true);
    } else {
      setSelectedId(2);
      setMachineView(false);
    }
  }, [deployData.nextStepProtocol]);

  const StackType = [
    {
      id: 1,
      image: !deployData.nextStepProtocol ? computer : computerSuccess,
      name: "Machine Setup",
    },
    {
      id: 2,
      image: gear,
      name: "Protocol Setup",

    }
  ];

  const getBorderStyle = (index: number, value: TStackType) => {
    if (index === 1) {
      return isSecondEnabled
        ? selectedId === value.id
          ? "2px solid #1781E2"
          : "1px solid #7B8A93"
        : "1px solid #D3D3D3";
    }
    return selectedId === value.id
      ? "2px solid #1781E2"
      : isFirstGreen
      ? "1px solid #1781E2"
      : !deployData.nextStepProtocol
      ? "1px solid #1781E2"
      : "1px solid #7B8A93";
  };

  return (
    <Box>
      <DeployStepHeading
        title="VM Setup"
        description="Please import all 4 vM’s, one by one so we can proceed to the next phase"
      />
      <Box>
        <Grid container item md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              Importing Stack
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#5F6368"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              {deployData.currentVm}/4
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          border: "1px solid #7B8A93",
          width: "100%",
          height: "22px",
          borderRadius: "5px",
        }}
      >
        <Grid container item md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                height: "15px",
                lineHeight: "15px",
                fontSize: "12px",
                textAlign: "left",
                color: "#7B8A93",
                paddingLeft: "4px",
                paddingTop: "2px",
                letterSpacing: "1px",
              }}
            >
              VM {deployData.currentVm}
            </Typography>
          </Grid>
          <Grid item md={1}>
            {loadVmSetup ? (
              <CircularProgress size={19} sx={{ fontSize: "12px" }} />
            ) : (
              <>{vmIcon}</>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          textAlign="right"
          sx={{ paddingTop: "10px" }}
        >
          {vmMessage}
        </Typography>
      </Box>

      <Grid container marginTop="16px" md={12} columnGap={6} flexWrap="nowrap">
        {StackType
          .filter((value) => value.id !== 2 || deployData.currentVm === 4)
          .map((value: TStackType, index: number) => (
          <Grid item md={5.5} marginLeft={deployData.currentVm === 4 ? 0  : "30%"}>
            <Box position="relative"  overflow="hidden" style={styleSetup}>
              {index === 0 && isSecondEnabled && (
                <Box
                  position="absolute"
                  left="-20px"
                  top="-14px"
                  sx={{
                    width: "54px",
                    height: "39px",
                    transform: "rotate(-44.333deg)",
                    background: "rgba(23, 129, 226, 0.40)",
                  }}
                >
                  <CheckIcon
                    style={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: "bold",
                      marginLeft: "15px",
                      marginTop: "17px",
                      transform: "rotate(45.333deg)",
                    }}
                  />
                </Box>
              )}
              <Box
                onClick={() => handleClick(value.id)}
                sx={{
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  display: "flex",
                  // width: "191px",
                  height: "96px",
                  border: getBorderStyle(index, value),
                  justifyContent: "center",
                  alignItems: "center",
                  pointerEvents:
                    index === 1 && !isSecondEnabled ? "none" : "auto",
                  cursor:
                    index === 1 && !isSecondEnabled ? "not-allowed" : "pointer",
                  boxShadow: "0px 1px 10px 1px rgba(0, 0, 0, 0.10)",
                  // cursor: "pointer",
                  "&:hover": {
                    border: index === 1 ? "" : "2px solid #1781E2",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img src={value.image} width="50px" />
                  <Typography
                    textAlign="center"
                    color={
                      index === 1 || !deployData.nextStepProtocol
                        ? "#5F6368"
                        : "rgba(23, 129, 226, 0.60)"
                    }
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                    fontFamily="Montserrat"
                  >
                    {value.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box marginTop="24px">
        {machineView ? <MachineSetupOrigin /> :  <ProtocolSetupOrigin /> }
      </Box>
    </Box>
  );
};
