import React, { FC, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { fonts, colors } from "../../style/themes";

import { IinformationUser } from "../../type";
import { Button, Tooltip } from "@mui/material";
import { ModalResetPassword } from "./modalResetPassword";

interface IProps {
  userData: IinformationUser | null;
  setIsEditing: (value: boolean) => void;
  tokenUser: string
}

export const DataProfile: FC<IProps> = (props) => {
  const { userData, setIsEditing, tokenUser } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleNavigate = (nameFrist: any) => {
    setIsEditing(true);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        padding: "0 39px",
      }}
    >
      <Grid container item columnSpacing={"30px"} sx={{ marginLeft: "30px" }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
              marginLeft: "-60px",
              textAlign: "start",
              textTransform: "uppercase",
            }}
          >
            INFORMATION
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ pt: "36px" }}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            First Name
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.firstName || "-"}
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ pt: "36px" }}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Last Name
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.lastName || "-"}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Job Title
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.jobTitle || "-"}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Mobile
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.phone || "-"}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Mail
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.email || "-"}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Password
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.pass1 || "-"}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: "start",
              mb: "34px",
            }}
          >
            Confirm Password
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: "start",
              pb: "34px",
            }}
          >
            {userData?.pass2 || "-"}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sx={{ justifyContent: "end", margin: "10px 0 0 -60px" }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Tooltip
              title="Unable to edit information yet"
              placement="top"
              arrow
            >
              <Grid item sx={{}}>
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  sx={{
                    fontSize: fonts.size[200],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    padding: "4px 0",
                    width: "169px",
                    height: "40px",
                    mb: "39px",
                    borderRadius: "10px",
                    textTransform: "initial",
                  }}
                >
                  Change Password
                </Button>
              </Grid>
            </Tooltip>

            <ModalResetPassword
              title="Change your password"
              open={open}
              setOpen={setOpen}
              tokenUser={tokenUser}
            />

            <Tooltip
              title="Unable to edit information yet"
              placement="top"
              arrow
            >
              <Grid item sx={{}}>
                <Button
                  // disabled
                  onClick={() => handleNavigate(userData?.firstName)}
                  variant="contained"
                  sx={{
                    fontSize: fonts.size[200],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    padding: "4px 0",
                    width: "169px",
                    height: "40px",
                    mb: "39px",
                    borderRadius: "10px",
                    textTransform: "initial",
                  }}
                >
                  Edit Info
                </Button>
              </Grid>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
