import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";

import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { WizardContext } from "../../../../../../context/wizardContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import AttachFileIcon from "@material-ui/icons/AttachFile";

export const MachineSetupOrigin = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [selectedId, setSelectedId] = useState(null);
  const [touched, setTouched] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [errorIp, setErrorIp] = useState(false);

  const validateNodeName = (nodeName: string) => {
    if (nodeName) {
      nodeName = nodeName
        .replace(" ", "")
        .replace(/[^\w\s]/gi, "")
        .replace("_", "");
      return nodeName;
    }
  };

  const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2A2B2E",
      color: "#fff",
      maxWidth: 220,
      borderRadius: "13px",
      backdropFilter: "blur(4.33707857131958px)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const validateUserName = (nodeName: string) => {
    if (nodeName) {
      nodeName = nodeName.replace(" ", "");
      return nodeName;
    }
  };

  const clearInputs = () => {
    if (deployData.clearAllOrigin) {
      setDeployData({
        ...deployData,
        clearAllOrigin: false,
      });
    } else {
      setDeployData({
        ...deployData,
        clearAllOrigin: true,
      });
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  function countIpOccurrences(ip: string, deployData: any): number {
    let count = 0;

    return count;
  }

  useEffect(() => {
    setErrorIp(false);
    setFile(deployData.fileSetup);
    if (
      deployData.vmIp &&
      deployData.name &&
      deployData.vmUsername &&
      deployData.fileSetup
    ) {
      setDeployData({
        ...deployData,
        machineSetup: true,
        protocolSetup: false,
        buttonColor: "#1976d2",
      });
    } else {
      setDeployData({
        ...deployData,
        machineSetup: false,
        protocolSetup: false,
        buttonColor: "#7b8a93",
      });
      return;
    }
  }, [
    deployData.vmIp,
    deployData.name,
    deployData.vmUsername,
    deployData.fileSetup,
  ]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setDeployData({ ...deployData, fileSetup: e.target.files[0] });
    }
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Card
      sx={{
        height: "300px",
        boxShadow: "none",
        marginBottom: "9px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container md={12} columnGap={6} flexWrap="nowrap">
        <Grid item md={5.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              sx={{
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              IP
            </Typography>
            <Box sx={{ position: "relative" }}>
              <OutlinedInput
                placeholder="Insert IP"
                // onBlur={() => setTouched(true)}
                type="string"
                value={deployData.vmIp}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    vmIp: e.target.value
                      .replace(" ", "")
                      .replace(/[^\d.]/g, ""),
                  })
                }
                sx={{
                  width: "114px",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.vmIp && (
                <FormHelperText error>{"typing vmIp"}</FormHelperText>
              )} */}
              {errorIp && (
                <FormHelperText error>{"IP is already in use"}</FormHelperText>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"Node"}
              <br />
              {"Name"}
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="node name"
                // onBlur={() => setTouched(true)}
                type="string"
                value={deployData.name}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    name: validateNodeName(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* { !deployData.name && (
                <FormHelperText error>{"typing name"}</FormHelperText>
              )} */}
            </Box>
          </Box>

          {deployData.currentVm > 1 && (
            <Box
              display="flex"
              marginTop="15px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                fontFamily="Montserrat"
                fontWeight="600"
                color="#3E474D"
                fontSize={"10px"}
                sx={{
                  textAlign: "left",
                  wordWrap: "break-word",
                }}
              >
                {"Domain"}
                <br />
                {"(optional)"}
              </Typography>
              <Box>
                <OutlinedInput
                  placeholder="domain"
                  // type="string"
                  value={deployData.domain}
                  onChange={(e) =>
                    setDeployData({
                      ...deployData,
                      domain: e.target.value,
                    })
                  }
                  sx={{
                    width: "114px",
                    height: "40px",
                    border: "none",
                    borderRadius: "10px",
                    padding: "0",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                  }}
                />
                {/* { !deployData.domain && (
                <FormHelperText error>{"typing domain"}</FormHelperText>
              )} */}
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item md={5.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="15px"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              sx={{
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Username
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="user name"
                type="string"
                // onBlur={() => setTouched(true)}
                value={deployData.vmUsername}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    vmUsername: validateUserName(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.vmUsername && (
                <FormHelperText error>
                  {"typing vmUsername"}
                </FormHelperText>
              )} */}
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color={file ? "#1781E2" : "#7B8A93"}
              fontSize={"13px"}
              sx={{
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Upload Private Key
            </Typography>

            <Box
              width="24px"
              height="24px"
              borderRadius="50px"
              border="1px solid #DEE1E6"
              onClick={handleBoxClick}
              sx={{
                cursor: "pointer",
                background: file ? "#1781E2" : "#7B8A93",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                hidden
                key={0}
                accept=".pem"
                onChange={handleFileChange}
              />
              {file ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <AttachFileIcon
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
              ) : (
                <SearchIcon
                  style={{
                    fontSize: 17,
                    color: "#fff",
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5.4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              onClick={clearInputs}
              fontFamily="Montserrat"
              fontWeight="600"
              color="#E14942"
              fontSize={"13px"}
              sx={{
                cursor: "pointer",
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Clear Vm Settings
            </Typography>

            <CustomTooltip
              title={"Type here to clear all vm settings"}
              placement="top"
              arrow
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: 20,
                  color: "#E14942",
                  paddingTop: "3px",
                  paddingLeft: "5px",
                }}
              />
            </CustomTooltip>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
