import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { CloseModal } from "~/assets";
import { Controller, useForm } from "react-hook-form";
import { ThemeProvider } from "@emotion/react";

import { customTheme } from "../../style";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { MemberService } from "../../services/member.service";
import { useSnackbar } from "notistack";

interface IModalResetPassword {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  tokenUser: string;
}

interface IState {
  password: string;
  showPassword: boolean;
}

export const ModalResetPassword = ({
  title,
  open,
  setOpen,
  tokenUser,
}: IModalResetPassword) => {
  const memberService = new MemberService();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    setErrorPassword(false);
  };

  const [valuesOldPass, setValuesOldPass] = useState<IState>({
    password: "",
    showPassword: false,
  });

  const [valuesPass, setValuesPass] = useState<IState>({
    password: "",
    showPassword: false,
  });

  const [valuesConfirmPass, setValuesConfirmPass] = useState<IState>({
    password: "",
    showPassword: false,
  });
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<any>({});

  const submitFormPassword = async () => {
    const { oldPassword, password, passwordConfirmation } = form.getValues();

    if (password !== passwordConfirmation) {
      setErrorPassword(true);
      return;
    }
    setLoading(true);
    const respResetPassword = await memberService.resetPassword(
      {
        oldPassword,
        password,
        passwordConfirmation,
      },
      tokenUser
    );
    setLoading(false);

    if ([400, 401, 403].includes(respResetPassword.status)) {
      enqueueSnackbar(respResetPassword.statusText, {
        variant: "error",
      });
    }

    if ([200].includes(respResetPassword.status)) {
      enqueueSnackbar("Success! Your password has been reset", {
        variant: "success",
      });
      handleClose();
    }
  };

  const handleClickOldPassword = () => {
    setValuesOldPass({
      ...valuesOldPass,
      showPassword: !valuesOldPass.showPassword,
    });
  };

  const handleClickShowPassword = () => {
    setValuesPass({
      ...valuesPass,
      showPassword: !valuesPass.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPass({
      ...valuesConfirmPass,
      showPassword: !valuesConfirmPass.showPassword,
    });
  };

  const oldPassIsdisabled = form.watch("oldPassword");
  const passIsdisabled = form.watch("password");
  const confirmPassIsdisabled = form.watch("passwordConfirmation");

  return (
    <Dialog
      aria-describedby="dialogo de confirmação"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#F1F3F4",
          borderRadius: "18px",
        },
      }}
    >
      <DialogTitle
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "25px",
            color: "#5F6368",
          },
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={3}
          color="#1781E2"
          alignSelf="center"
        >
          {title}
        </Typography>
      </DialogTitle>
      <Box
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: "16px",
          top: "20px",
          cursor: "pointer",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CloseModal />
      </Box>
      <Divider />
      <DialogContent>
        <ThemeProvider theme={customTheme}>
          <Grid container item md={12} lg={12}>
            <Grid item md={12} lg={12}>
              <InputLabel
                sx={{ marginBottom: "-20px" }}
                // error={!!form.formState.errors.oldPassword}
                shrink
                htmlFor="oldPassword"
              >
                Actual Password
              </InputLabel>
              <OutlinedInput
                sx={{ width: "100%", marginBottom: "30px" }}
                placeholder="Actual Password"
                type={!valuesOldPass.showPassword ? "password" : "text"}
                onChange={(e) => {
                  form.setValue("oldPassword", e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: "25px" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOldPassword}
                      edge="end"
                    >
                      {!valuesOldPass.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item md={12} lg={12}>
              <InputLabel
                sx={{ marginBottom: "-20px" }}
                // error={!!form.formState.errors.password}
                shrink
                htmlFor="password"
              >
                New Password
              </InputLabel>
              <OutlinedInput
                sx={{ width: "100%", marginBottom: "30px" }}
                placeholder="New Password"
                type={!valuesPass.showPassword ? "password" : "text"}
                onChange={(e) => {
                  form.setValue("password", e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: "25px" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {!valuesPass.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errorPassword && (
                <FormHelperText
                  sx={{ marginTop: "-25px", marginBottom: "30px" }}
                  error
                >
                  The password no Match
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={12} lg={12}>
              <InputLabel
                sx={{ marginBottom: "-20px" }}
                // error={!!form.formState.errors.passwordConfirmation}
                shrink
                htmlFor="passwordConfirmation"
              >
                Confirm new password
              </InputLabel>
              <OutlinedInput
                sx={{ width: "100%", marginBottom: "30px" }}
                placeholder="Confirm new password"
                // error={!!form.formState.errors.passwordConfirmation}
                type={!valuesConfirmPass.showPassword ? "password" : "text"}
                onChange={(e) => {
                  form.setValue("passwordConfirmation", e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: "25px" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {!valuesConfirmPass.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errorPassword && (
                <FormHelperText
                  sx={{ marginTop: "-25px", marginBottom: "30px" }}
                  error
                >
                  The password no Match
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                marginBottom="30px"
              >
                <Button
                  color="error"
                  sx={{
                    width: "30%",
                    marginRight: "50px",
                    display: "flex",
                    fontSize: "14px",
                    fontFamily: "MontSerrat",
                    fontWeight: "400",
                  }}
                  variant="contained"
                  onClick={handleClose}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Cancel
                  </Typography>
                </Button>
                <LoadingButton
                  loading={loading}
                  disabled={
                    !oldPassIsdisabled ||
                    !passIsdisabled ||
                    !confirmPassIsdisabled
                  }
                  sx={{
                    width: "30%",
                    display: "flex",
                    fontSize: "14px",
                    fontFamily: "MontSerrat",
                    fontWeight: "400",
                  }}
                  variant="contained"
                  onClick={submitFormPassword}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Submit
                  </Typography>
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
};
