import { useState } from "react";

import { ThemeProvider } from "@emotion/react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  styled,
  InputBase,
  DialogActions,
  Button,
  FormHelperText
} from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';

import InputMask from 'react-input-mask';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { customTheme } from "../../style";
import { ModalRemove } from "../modal-remove";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface IAddMembers {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  permission: string;
}

export type TAddmembers = {
  form: UseFormReturn<IAddMembers>;
  onSubmit: () => void;
  idMember?: string;
  idOrg?: string;
  handleCloseRemove: () => void;
  idMemberVerifed: any;
  noMatchPass: boolean;
  tokenUser: string;
  loadingMember: boolean;
  statusMember: string;
  memberEmailFiel: boolean;
};

export const AddMembers = ({
  form,
  onSubmit,
  idMember,
  idOrg,
  handleCloseRemove,
  idMemberVerifed,
  noMatchPass,
  tokenUser,
  loadingMember,
  statusMember,
  memberEmailFiel
}: TAddmembers) => {

  const [open, setOpen] = useState<boolean>(false);
  const [permissionUser, setPermissionUser] = useState<string>('');
  let owner: boolean;

  const profileUser: any = useSelector(
		(state: any) => state.profileUser
	);

  try {
    owner = localStorage.getItem('owner') !== "";
  } catch (error) {
    owner = false;
  }

  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      textAlign: 'start',
      borderRadius: '10px',
      backgroundColor: '#FAFAFA',
      border: '1px solid #A2CBFE',
      '&:focus': {
        borderRadius: '10px',
      },
      ':after': {
        borderBottom: 'none',
        borderRight: '1px solid #A2CBFE',
        marginRight: '50px',
      }
    },
  }));

  useEffect(() => {
    !owner && profileUser !== 'admin'
      ? setPermissionUser('stakeholder')
      : setPermissionUser('owner');
  }, [owner])

  return (
    <form onSubmit={() => { }}>
      <ThemeProvider theme={customTheme}>
        <Grid container item md={12} spacing={2} sx={{ marginBottom: '24px' }}>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.firstName}
                    shrink
                    htmlFor="firstName"
                  >
                    First Name
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    disabled={(idMemberVerifed || idMember)
                      ? true : false}
                    placeholder="type your name"
                    error={!!form.formState.errors.firstName}
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('firstName', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.firstName && (
                    <FormHelperText error>
                      {form?.formState?.errors?.firstName?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.lastName}
                    shrink
                    htmlFor="lastName"
                  >
                    Last Name
                  </InputLabel>
                  <OutlinedInput
                    disabled={(idMemberVerifed || idMember) ? true : false}
                    error={!!form.formState.errors.lastName}
                    placeholder="type your last Name"
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('lastName', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.lastName && (
                    <FormHelperText error>
                      {form?.formState?.errors?.lastName?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="jobTitle"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.jobTitle}
                    shrink
                    htmlFor="jobTitle"
                  >
                    Job Title
                  </InputLabel>
                  <OutlinedInput
                    disabled={(idMemberVerifed || idMember) ? true : false}
                    error={!!form.formState.errors.jobTitle}
                    placeholder="type the title job"
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('jobTitle', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.jobTitle && (
                    <FormHelperText error>
                      {form?.formState?.errors?.jobTitle?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.phone}
                    shrink
                    htmlFor="phone"
                  >
                    Mobile
                  </InputLabel>
                  <InputMask
                    autoComplete="off"
                    type="tel"
                    disabled={(idMemberVerifed || idMember) ? true : false}
                    mask="(99) 99999-9999"
                    value={field.value}
                    onChange={(e: any) => {
                      form.setValue('phone', e.target.value);
                    }}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {() => (
                      <OutlinedInput
                        disabled={(idMemberVerifed || idMember) ? true : false}
                        style={{ opacity: '.9' }}
                        error={!!form.formState.errors.phone}
                        placeholder="type your phone number"
                        type="string"
                      />
                    )}
                  </InputMask>
                  {!!form.formState.errors.phone && (
                    <FormHelperText error>
                      {form?.formState?.errors?.phone?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <Controller
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.email}
                    shrink
                    htmlFor="email"
                  >
                    Mail
                  </InputLabel>
                  <OutlinedInput
                    disabled={(idMemberVerifed || idMember || memberEmailFiel) ? true : false}
                    error={!!form.formState.errors.email}
                    placeholder="type your email"
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('email', e.target.value);
                    }}
                  />

                  {!!form.formState.errors.email && (
                    <FormHelperText error>
                      {form?.formState?.errors?.email?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.password}
                    shrink
                    htmlFor="password"
                  >
                    Temporary Password
                  </InputLabel>
                  <OutlinedInput
                    error={!!form.formState.errors.password}
                    disabled={(idMemberVerifed || idMember) ? true : false}
                    placeholder="type the password temporary"
                    {...field}
                    type="password"
                    onChange={e => {
                      form.setValue('password', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.password && (
                    <FormHelperText error>
                      {form?.formState?.errors?.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Controller
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    error={noMatchPass}
                    disabled={(idMemberVerifed || idMember) ? true : false}
                    placeholder="type your password again"
                    {...field}
                    type="password"
                    onChange={e => {
                      form.setValue('confirmPassword', e.target.value);
                    }}
                  />
                  {noMatchPass && (
                    <FormHelperText error>
                      {'The password dont match!'}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12} marginTop="20px">
            <Controller
              control={form.control}
              name="permission"
              render={({ field }) => (
                <FormControl required variant="standard" fullWidth>
                  <InputLabel
                    sx={{ marginBottom: '-12px' }}
                    shrink
                    htmlFor="permission"
                    id="demo-simple-select-label"
                  >
                    Permission
                  </InputLabel>
                  <Select
                    disabled={profileUser === 'stakeholder' || statusMember === 'owner' }
                    {...field}
                    fullWidth
                    input={<BootstrapInput />}
                    labelId="demo-simple-select-label"
                    id="permission"
                    variant="standard"
                    onChange={e => {
                      form.setValue('permission', e.target.value)
                    }}
                    sx={{
                      ':before': {
                        borderRight: '1px solid #A2CBFE',
                        marginRight: '50px',
                        height: '100%',
                      },
                    }}
                  >
                    <MenuItem value={'admin'}>Admin</MenuItem>
                    <MenuItem value={'stakeholder'}>Stakeholder</MenuItem>
                    <MenuItem disabled value={'owner'}>Owner</MenuItem>
                  </Select>
                </FormControl>

              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={12}
          display="flex"
          justifyContent="center"
          marginTop="20px"
          marginBottom="24px"
        >
          {!idMember &&
            <Grid item md={10}>
              <DialogActions>
                <LoadingButton
                  loading={loadingMember}
                  fullWidth
                  onClick={() => form.handleSubmit(onSubmit)()}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  variant="contained"
                // onClick={changeState}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Submit
                  </Typography>
                </LoadingButton>
              </DialogActions>
            </Grid>
          }
          {idMember &&
            <Grid
              container
              padding="10px"
              md={12}
              sm={12}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid padding="10px" item md={4} sm={12} sx={{ padding: '98px 20x 0' }}>
                <Button
                  color="error"
                  onClick={() => setOpen(true)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Remove
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={4} sm={12}>
                <Button
                  sx={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                  onClick={() => form.handleSubmit(onSubmit)()}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Save
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          }
          <ModalRemove
            title="Remove Member"
            setOpen={setOpen}
            open={open}
            content="Are you sure you want to remove this member?"
            idMember={idMember}
            idOrg={idOrg}
            handleCloseRemove={handleCloseRemove}
            tokenUser={tokenUser}
          />
        </Grid>
      </ThemeProvider>
    </form >
  )
}