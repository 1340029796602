import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";

import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { WizardContext } from "../../context/wizardContext";
import { ports } from "../../ports";

export const NetworkSetup = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const [touched, setTouched] = useState(false);
  const [errorClient, setErrorClient] = useState(false);

  const clearInputs = () => {
    if (deployData.clearAll) {
      setDeployData({
        ...deployData,
        clearAll: false,
      });
    } else {
      setDeployData({
        ...deployData,
        clearAll: true,
      });
    }
  };

  useEffect(() => {
    if (errorClient) {
      setDeployData({
        ...deployData,
        buttonColor: "#7b8a93",
        protocolDoorman: false,
        protocolSetup: false,
        machineSetup: false,
        sameValues: true,
      });
      return
    } else {
      setDeployData({
        ...deployData,
        buttonColor: "#1976d2",
        protocolDoorman: true,
        protocolSetup: false,
        machineSetup: false,
      });
    }

    if (deployData.clientPort) {
      setDeployData({
        ...deployData,
        buttonColor: "#1976d2",
        protocolDoorman: true,
        protocolSetup: false,
        machineSetup: false,
      });
    } else {
      setDeployData({
        ...deployData,
        protocolDoorman: false,
        protocolSetup: false,
        machineSetup: false,
      });
      return;
    }
  }, [deployData.clientPort]);

  const validatePort = (clientPort: string) => {
    const isClientPortncluded = ports.includes(clientPort);

    setErrorClient(isClientPortncluded);
    if (clientPort) {
      clientPort = clientPort?.replace(/\D/g, "");
      return clientPort;
    }
  };

  return (
    <Card
      sx={{
        height: "300px",
        boxShadow: "none",
        marginBottom: "9px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container item md={12} columnGap={6} flexWrap="nowrap" padding="0">
        <Grid item md={5.5}>
          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="25px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              Client Port
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="client port"
                type="string"
                value={deployData.clientPort}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    clientPort: validatePort(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {errorClient && (
                <FormHelperText error>{"type different ports"}</FormHelperText>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item md={5.4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              onClick={clearInputs}
              fontFamily="Montserrat"
              fontWeight="600"
              color="#E14942"
              fontSize={"13px"}
              sx={{
                cursor: "pointer",
                height: "40px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Clear Vm Settings
            </Typography>

            <InfoOutlinedIcon
              style={{
                fontSize: 20,
                color: "#E14942",
                paddingTop: "3px",
                paddingLeft: "5px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
