import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../context/wizardContext";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import type { StepIconProps } from "@mui/material/StepIcon";

import { DeployBaasSteps } from "../../../../pages/DeployBaas/DeployBaasSteps";
import { DeployAuthorization } from "../../../deployAuthorization";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { cryptoWaitReady } from "@polkadot/util-crypto";
import uiKeyring from "@polkadot/ui-keyring";
import { UserService } from "../../../../services/user.services";
import { useSnackbar } from "notistack";
import { textError } from "../../../../utils/textError";
import axios from "axios";

const steps = ["", "", "", "", "", "", ""];
let nextStepVM = false;
let nextStepVMSub = false;
let nextStepVMBesu = false;

export const DeployBaasWizard = ({ tokenUser, user }: any) => {
  const userService = new UserService();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [deployStep, setDeployStep] = useState(0);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [responseVmCheck, setResponseVmCheck] = useState<number>(0);
  const [responseVmCheckBesu, setResponseVmCheckNode] = useState<number>(0);

  const [backPage, setBackPage] = useState<boolean>(false);
  const [backPageSub, setBackPageSub] = useState<boolean>(false);
  
  const [backPageBesu, setBackPageBesu] = useState<boolean>(false);

  const [passwordAuthorization, setPasswordAuthorization] =
    useState<string>("");

  const [fezLoad, setFezLoad] = useState<boolean>(false);
  const [stepNotary, setStepNotary] = useState<boolean>(false);
  const [collorButton, setCollorButton] = useState<boolean>(false);

  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [paginaNot, setPaginaNot] = useState(deployData.versionNotarie);
  useEffect(() => {
    setPaginaNot(deployData.versionNotarie);
  }, [deployData.versionNotarie]);

  const [stepNode, setStepNode] = useState<number>();
  const [pagina, setPagina] = useState(1);
  const [pageSub, setPageSub] = useState(1);
  const [pageBesu, setPageBesu] = useState(1);

  const [paginaVirt, setPaginaVirt] = useState(0);

  useEffect(() => {
    if (!deployData.valueNode || deployData.valueNode === "Unlimited") return;
    const numberNode = Number(deployData?.valueNode?.match(/\d+/)[0]);
    if (numberNode > 0) {
      setStepNode(numberNode);
    }
  }, [deployData.valueNode]);

  useEffect(() => {
    setBackPage(false);
    setPagina(1);
    setPaginaVirt(0);
    return setDeployData({
      ...deployData,
      validateNotary: false,
      // backUpdate: false,
      fileSetupCorda: null,
      currentVmEnt: 1,
      corda: {
        regularNodes: [],
        notaries: [],
        doorman: [],
      },
      cordaCopy: {
        generalData: [],
      },
      sshdPort: "",
      vmIpCorda: "",
      nameCorda: "",
      vmUsernameCorda: "",
      portRpc: "",
      dbPassword: "",
      portP2P: "",
      clientPort: "",
      rpcPassword: "",
      portRpcAdmin: "",
      nameProtocol: "",
      dbUsername: "",
      rpcUsername: "",
      nodeCity: "",
      nodeCountry: "",
      // keyStorePassword: "",
      // trustStorePassword: "",
      nextStepProtocol: false,
      machineSetup: false,
      protocolSetup: false,
      setupNotarie: false,
      setupDoorman: false,
      protocolDoorman: false,
    });
  }, [deployData.clearAll]);

  const clearAllFieds = () => {
    setPageSub(1);
    setBackPageSub(false);
    return setDeployData({
      ...deployData,
      fileSetup: null,
      currentVmEntSub: 1,
      substrate: {
        nodes: [],
      },
      besu: {
        nodes: [],
      },
      vmIp: "",
      name: "",
      vmUsername: "",
      mnemonic: "",
      nextStepProtocol: false,
      machineSetup: false,
      protocolSetup: false,
    });
  };

  useEffect(() => {
    clearAllFieds();
  }, [deployData.clearAllSub]);

  const [selectedOrg, setSelectedOrg] = useState("");

  const [networkName, setNetworkName] = useState("");

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const submitNameAndDescription = async () => {
    try {
      if (deployData.networkName) {
        const respValidateNameNetwork = await userService.userAreThere(
          deployData.orgId,
          deployData.networkName,
          deployData.token
        );
        if (respValidateNameNetwork.data.available) {
          return respValidateNameNetwork.data.available;
        } else {
          return respValidateNameNetwork.data.available;
        }
      }
    } catch (error: any) {
      const { statusCode, message } = error?.response?.data;
      if ([403, 400, 404].includes(statusCode)) {
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
      console.error(error);
    }
  };

  const handleNextStep = async () => {
    if (deployStep === 6) {
      setCollorButton(false);
      window.location.reload();
    }
    if (deployStep === 0) {
      setCollorButton(false);
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 1) {
      setCollorButton(false);
      setDeployData({ ...deployData });
      if (deployData.selectedMode !== "") {
        setDeployStep(deployStep + 1);
      }
    }
    if (
      deployStep === 2 &&
      deployData.selectedOrg !== "" &&
      deployData.enterpriseId !== "" &&
      deployData.networkName !== "" &&
      deployData.protocolType !== "" &&
      deployData.version !== ""
    ) {
      setCollorButton(false);
      setDeployData({ ...deployData, consesus: "", selectedStack: "" });
      const result = await submitNameAndDescription();

      if (result) {
        setDeployStep(deployStep + 1);
      } else {
        enqueueSnackbar("this network name already exists!", {
          variant: "error",
        });
        setDeployStep(deployStep);
      }
    }
    if (deployData.protocolType !== "substrate") {
      if (deployStep === 3 && deployData.selectedStack === "enterprise") {
        setCollorButton(false);
        setDeployStep(deployStep + 1);
      }

      if (
        (deployStep === 3 && deployData.selectedStack === "mvp") ||
        (deployData.selectedStack === "development" &&
          deployData.consesus === "QBFT" &&
          deployStep !== 4)
      ) {
        setCollorButton(false);
        setDeployStep(deployStep + 1);
      }
    } else {
      if (deployStep === 3 && deployData.selectedStack === "mvp") {
        setCollorButton(false);
        setDeployStep(deployStep + 1);
      }
    }

    let responseCheck = 0;
    let responseCheckNode = 0;

    const vmSetupNodeCheck = async (vmIp: string, vmUsername: string) => {
      const varFormData = new FormData();
      varFormData.append("organizationId", deployData.orgId);
      varFormData.append("ip", vmIp);
      varFormData.append("username", vmUsername);

      if (deployData.fileSetup) {
        varFormData.append("file", deployData.fileSetup);
      }
      try {
        setDeployData({ ...deployData, loadVmCheck: true });
        return axios
          .post(
            `${process.env.REACT_APP_API_URL_ADDRESS}/vm/available/baas`,
            varFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + tokenUser,
              },
            }
          )
          .then((response) => {
            setDeployData({ ...deployData, loadVmCheck: false });
            if (response.status === 201) {
              setResponseVmCheckNode(response.status);
              responseCheckNode = response.status;
              console.log(response.data);
            }
            // aqui acessamos o corpo da resposta:
          })
          .catch((error) => {
            setDeployData({ ...deployData, loadVmCheck: false });
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            setResponseVmCheckNode(400);
            responseCheckNode = 400;
            console.log(error);
          });
      } catch (error) {
        setDeployData({ ...deployData, loadVmCheck: false });
        setResponseVmCheck(400);
        console.log(error);
      }
    };

    const vmSetupNode = async (
      ipCorda: string,
      userNameCorda: string,
      nameCorda: string
    ) => {
      const varFormData = new FormData();
      varFormData.append("organizationId", deployData.orgId);
      varFormData.append("ip", ipCorda);
      varFormData.append("username", userNameCorda);

      if (deployData.fileSetupCorda) {
        varFormData.append("file", deployData.fileSetupCorda);
      }
      try {
        setDeployData({ ...deployData, loadVmCheck: true });
        return axios
          .post(
            `${process.env.REACT_APP_API_URL_ADDRESS}/vm/available/baas`,
            varFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + tokenUser,
              },
            }
          )
          .then((response) => {
            setDeployData({ ...deployData, loadVmCheck: false });
            if (response.status === 201) {
              setResponseVmCheck(response.status);
              responseCheck = response.status;
            }
            // aqui acessamos o corpo da resposta:
          })
          .catch((error) => {
            setDeployData({ ...deployData, loadVmCheck: false });
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            setResponseVmCheck(400);
            responseCheck = 400;
            console.log(error);
          });
      } catch (error) {
        setDeployData({ ...deployData, loadVmCheck: false });
        setResponseVmCheck(400);
        console.log(error);
      }
    };

    const loadNextPageBesu = () => {
      setDeployStep(deployStep + 1);
    };

    const loadNextPageSub = () => {
      // fazer o load aqui!
      const dataMachine = deployData.substrate.nodes[pageSub]?.machineSetup;
      const dataProtocol = deployData.substrate.nodes[pageSub]?.protocolSetup;
      setPageSub(pageSub + 1);
      if (pageSub !== 3) {
        return setDeployData({
          ...deployData,
          currentVmEntSub: pageSub + 1,
          vmIp: dataMachine?.ip,
          name: dataMachine?.nodeName,
          vmUsername: dataMachine?.username,
          mnemonic: dataProtocol?.mnemonic,
          nextStepProtocol: false,
          machineSetup: true,
          protocolSetup: true,
        });
      } else {
        setDeployStep(deployStep + 1);
      }
    };

    const nextPageSub = () => {
      setPageSub(pageSub + 1);
      if (pageSub !== 3) {
        return setDeployData({
          ...deployData,
          currentVmEntSub: pageSub + 1,
          vmIp: "",
          name: "",
          vmUsername: "",
          mnemonic: "",
          nextStepProtocol: false,
          machineSetup: false,
          protocolSetup: false,
        });
      } else {
        setDeployStep(deployStep + 1);
      }
    };


    // AVANÇAR DENTRO DO VM - MACHINE SETUP E PROTOCOLSETUP DO SUBSTRATE
    if (deployStep === 4 && deployData.selectedStack === "mvp") {
      if (deployData.machineSetup) {
        setDeployData({ ...deployData, nextStepProtocol: true });
      }

      if (deployData.protocolSetup) {
        const dataSubstrate = deployData.substrate.nodes;
        await vmSetupNodeCheck(deployData.vmIp, deployData.vmUsername);

        if (backPageSub) {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheckNode)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
          if (responseCheckNode === 201) {
            if (backPageSub) {
              dataSubstrate[pageSub - 1] = {
                machineSetup: {
                  ip: deployData.vmIp,
                  username: deployData.vmUsername,
                  nodeName: deployData.name,
                },
                protocolSetup: {
                  mnemonic: deployData.mnemonic,
                },
              };
              nextStepVMSub = true;
            }
          }
        } else if (responseCheckNode === 201) {
          dataSubstrate.push({
            machineSetup: {
              ip: deployData.vmIp,
              username: deployData.vmUsername,
              nodeName: deployData.name,
            },
            protocolSetup: {
              mnemonic: deployData.mnemonic,
            },
          });
          nextStepVMSub = true;
        } else {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheckNode)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
        }
      }
    }

    // Essa parte é para anvançar no VMsetup do SUBSTRATE
    if (
      deployStep === 4 &&
      deployData.selectedStack === "mvp" &&
      nextStepVMSub
    ) {
      if (pageSub < 4) {
        nextStepVMSub = false;
        backPageSub ? loadNextPageSub() : nextPageSub();
      }
    }

    // Parte para avançar no VMsetup do Besu
    if (
      deployStep === 4 &&
      deployData.selectedStack === "development" &&
      deployData.consesus === "QBFT" &&
      nextStepVMBesu
    ) {
      if (pageBesu === 1) {
        loadNextPageBesu();
      }
    }

    // BESU
    if (
      deployStep === 4 &&
      deployData.selectedStack === "development" &&
      deployData.consesus === "QBFT"
    ) {
      if (deployData.machineSetup) {
        setDeployData({ ...deployData, nextStepProtocol: true });
      }

      if (deployData.protocolSetup) {
        const dataBesu = deployData.besu.nodes;
        await vmSetupNodeCheck(deployData.vmIp, deployData.vmUsername);

        if (backPageBesu) {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheckNode)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
          if (responseCheckNode === 201) {
            if (backPageBesu) {
              dataBesu[pageBesu - 1] = {
                machineSetup: {
                  ip: deployData.vmIp,
                  username: deployData.vmUsername,
                  nodeName: deployData.name,
                },
                protocolSetup: {
                  mnemonic: deployData.mnemonic,
                },
              };
              setDeployStep(deployStep + 1);
            }
          }
        } else if (responseCheckNode === 201) {
          dataBesu.push({
            machineSetup: {
              ip: deployData.vmIp,
              username: deployData.vmUsername,
              nodeName: deployData.name,
            },
            protocolSetup: {
              mnemonic: deployData.mnemonic,
            },
          });
          setDeployStep(deployStep + 1);
        } else {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheckNode)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
        }
      }
    }
    
    // Corda
    if (deployStep === 4 && deployData.selectedStack === "enterprise") {
      setCollorButton(false);
      // *** NEW SCREEN************************
      if (deployData.setupDoorman && deployData.machineSetup) {
        setBackPage(false);
        setDeployData({ ...deployData, nextStepProtocol: true });
      }

      if (deployData.protocolDoorman && deployData.setupDoorman) {
        const dataCordaRegular = deployData.corda.doorman;
        const cordaCopy = deployData.cordaCopy.generalData;

        await vmSetupNode(
          deployData.vmIpCorda,
          deployData.vmUsernameCorda,
          deployData.nameCorda
        );
        if (backPage) {
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
          if (responseCheck === 201) {
            cordaCopy[pagina - 1] = {
              machineSetup: {
                username: deployData.vmUsernameCorda,
                ip: deployData.vmIpCorda,
                nodeName: deployData.nameCorda,
              },
              protocolSetup: {
                clientPort: deployData.clientPort,
              },
            };

            dataCordaRegular[0] = {
              machineSetup: {
                username: deployData.vmUsernameCorda,
                ip: deployData.vmIpCorda,
                nodeName: deployData.nameCorda,
              },
              protocolSetup: {
                clientPort: deployData.clientPort,
              },
            };
          }
        } else if (responseCheck === 201) {
          cordaCopy.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              clientPort: deployData.clientPort,
            },
          });

          dataCordaRegular.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              clientPort: deployData.clientPort,
            },
          });
        } else {
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
        }
      }

      if (
        deployData.machineSetup &&
        !deployData.setupNotarie &&
        !deployData.setupDoorman
      ) {
        setDeployData({ ...deployData, nextStepProtocol: true });
      } else if (
        deployData.machineSetup &&
        deployData.setupNotarie &&
        !deployData.setupDoorman
      ) {
        setDeployData({ ...deployData, nextStepProtocol: true });
      }
      if (deployData.protocolSetup && !deployData.setupNotarie) {
        // codigo do  regular node PROTOCOLSETUP

        const dataCordaRegular = deployData.corda.regularNodes;
        const cordaCopy = deployData.cordaCopy.generalData;

        await vmSetupNode(
          deployData.vmIpCorda,
          deployData.vmUsernameCorda,
          deployData.nameCorda
        );
        if (backPage) {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }

          if (responseCheck === 201) {
            if (backPage) {
              dataCordaRegular[pagina - 1] = {
                machineSetup: {
                  username: deployData.vmUsernameCorda,
                  ip: deployData.vmIpCorda,
                  nodeName: deployData.nameCorda,
                },
                protocolSetup: {
                  portRPCAdmin: deployData.portRpcAdmin,
                  portRPC: deployData.portRpc,
                  dbPassword: deployData.dbPassword,
                  rpcPassword: deployData.rpcPassword,
                  portP2P: deployData.portP2P,
                  dbUsername: deployData.dbUsername,
                  rpcUsername: deployData.rpcUsername,
                  nodeCity: deployData.nodeCity,
                  nodeCountry: deployData.nodeCountry,
                  // keyStorePassword: deployData.keyStorePassword,
                  // trustStorePassword: deployData.trustStorePassword,
                  sshdPort: deployData.sshdPort,
                },
              };
              cordaCopy[pagina - 1] = {
                machineSetup: {
                  username: deployData.vmUsernameCorda,
                  ip: deployData.vmIpCorda,
                  nodeName: deployData.nameCorda,
                },
                protocolSetup: {
                  portRPCAdmin: deployData.portRpcAdmin,
                  portRPC: deployData.portRpc,
                  dbPassword: deployData.dbPassword,
                  rpcPassword: deployData.rpcPassword,
                  portP2P: deployData.portP2P,
                  dbUsername: deployData.dbUsername,
                  rpcUsername: deployData.rpcUsername,
                  nodeCity: deployData.nodeCity,
                  nodeCountry: deployData.nodeCountry,
                  // keyStorePassword: deployData.keyStorePassword,
                  // trustStorePassword: deployData.trustStorePassword,
                  sshdPort: deployData.sshdPort,
                },
              };
              nextStepVM = true;
            }
          }
        } else if (responseCheck === 201) {
          dataCordaRegular.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              portRPCAdmin: deployData.portRpcAdmin,
              portRPC: deployData.portRpc,
              dbPassword: deployData.dbPassword,
              rpcPassword: deployData.rpcPassword,
              portP2P: deployData.portP2P,
              dbUsername: deployData.dbUsername,
              rpcUsername: deployData.rpcUsername,
              nodeCity: deployData.nodeCity,
              nodeCountry: deployData.nodeCountry,
              // keyStorePassword: deployData.keyStorePassword,
              // trustStorePassword: deployData.trustStorePassword,
              sshdPort: deployData.sshdPort,
            },
          });
          cordaCopy.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              portRPCAdmin: deployData.portRpcAdmin,
              portRPC: deployData.portRpc,
              dbPassword: deployData.dbPassword,
              rpcPassword: deployData.rpcPassword,
              portP2P: deployData.portP2P,
              dbUsername: deployData.dbUsername,
              rpcUsername: deployData.rpcUsername,
              nodeCity: deployData.nodeCity,
              nodeCountry: deployData.nodeCountry,
              // keyStorePassword: deployData.keyStorePassword,
              // trustStorePassword: deployData.trustStorePassword,
              sshdPort: deployData.sshdPort,
            },
          });
          nextStepVM = true;
        } else {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
        }
      } else if (deployData.protocolSetup && deployData.setupNotarie) {
        // CODIGO DO NOTARY PROTOCOLSETUP
        await vmSetupNode(
          deployData.vmIpCorda,
          deployData.vmUsernameCorda,
          deployData.nameCorda
        );

        const dataCordaRegular = deployData.corda.notaries;
        const cordaCopy = deployData.cordaCopy.generalData;
        // setPaginaVirt(paginaVirt + 1);
        if (backPage) {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }

          if (responseCheck === 201) {
            setPaginaVirt(paginaVirt + 1);
            cordaCopy[pagina - 1] = {
              machineSetup: {
                username: deployData.vmUsernameCorda,
                ip: deployData.vmIpCorda,
                nodeName: deployData.nameCorda,
              },
              protocolSetup: {
                portRPCAdmin: deployData.portRpcAdmin,
                portRPC: deployData.portRpc,
                dbPassword: deployData.dbPassword,
                rpcPassword: deployData.rpcPassword,
                portP2P: deployData.portP2P,
                dbUsername: deployData.dbUsername,
                rpcUsername: deployData.rpcUsername,
                nodeCity: deployData.nodeCity,
                nodeCountry: deployData.nodeCountry,
                notaryValidating: deployData.validateNotary,
                // keyStorePassword: deployData.keyStorePassword,
                // trustStorePassword: deployData.trustStorePassword,
                sshdPort: deployData.sshdPort,
              },
            };
            dataCordaRegular[paginaVirt] = {
              machineSetup: {
                username: deployData.vmUsernameCorda,
                ip: deployData.vmIpCorda,
                nodeName: deployData.nameCorda,
              },
              protocolSetup: {
                portRPCAdmin: deployData.portRpcAdmin,
                portRPC: deployData.portRpc,
                dbPassword: deployData.dbPassword,
                rpcPassword: deployData.rpcPassword,
                portP2P: deployData.portP2P,
                dbUsername: deployData.dbUsername,
                rpcUsername: deployData.rpcUsername,
                nodeCity: deployData.nodeCity,
                nodeCountry: deployData.nodeCountry,
                notaryValidating: deployData.validateNotary,
                // keyStorePassword: deployData.keyStorePassword,
                // trustStorePassword: deployData.trustStorePassword,
                sshdPort: deployData.sshdPort,
              },
            };
            nextStepVM = true;
          }
        } else if (responseCheck === 201) {
          setPaginaVirt(paginaVirt + 1);
          setStepNotary(true);
          dataCordaRegular.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              portRPCAdmin: deployData.portRpcAdmin,
              portRPC: deployData.portRpc,
              dbPassword: deployData.dbPassword,
              rpcPassword: deployData.rpcPassword,
              portP2P: deployData.portP2P,
              dbUsername: deployData.dbUsername,
              rpcUsername: deployData.rpcUsername,
              nodeCity: deployData.nodeCity,
              nodeCountry: deployData.nodeCountry,
              notaryValidating: deployData.validateNotary,
              // keyStorePassword: deployData.keyStorePassword,
              // trustStorePassword: deployData.trustStorePassword,
              sshdPort: deployData.sshdPort,
            },
          });
          cordaCopy.push({
            machineSetup: {
              username: deployData.vmUsernameCorda,
              ip: deployData.vmIpCorda,
              nodeName: deployData.nameCorda,
            },
            protocolSetup: {
              portRPCAdmin: deployData.portRpcAdmin,
              portRPC: deployData.portRpc,
              dbPassword: deployData.dbPassword,
              rpcPassword: deployData.rpcPassword,
              portP2P: deployData.portP2P,
              dbUsername: deployData.dbUsername,
              rpcUsername: deployData.rpcUsername,
              nodeCity: deployData.nodeCity,
              nodeCountry: deployData.nodeCountry,
              notaryValidating: deployData.validateNotary,
              // keyStorePassword: deployData.keyStorePassword,
              // trustStorePassword: deployData.trustStorePassword,
              sshdPort: deployData.sshdPort,
            },
          });
          nextStepVM = true;
        } else {
          setDeployData({
            ...deployData,
            nextStepProtocol: false,
            machineSetup: true,
          });
          if ([400, 404].includes(responseCheck)) {
            enqueueSnackbar("error on vmcheck", {
              variant: "error",
            });
            return;
          }
        }
      }
    }
    // **************
    // Ver isso aqui
    
    if (
      deployStep === 4 &&
      deployData.currentVm === `${stepNode} - lastNode` &&
      deployData.fileSetup
    ) {

      setDeployData({ ...deployData });
      setDeployStep(deployStep + 1);
    }

    // Saber até onde vai o versionNotarie e o VersionRegular
    const loadNextPageData = (
      setupRegularNode: boolean,
      setupNotarie: boolean,
      setupDoorman: boolean
    ) => {
      const loadFieldM = deployData.cordaCopy.generalData[pagina]?.machineSetup;

      const loadFielP = deployData.cordaCopy.generalData[pagina]?.protocolSetup;

      setPagina(pagina + 1);
      return setDeployData({
        ...deployData,
        nextStepProtocol: !deployData.protocolDoorman ? false : true,
        setupRegularNode,
        setupNotarie,
        setupDoorman,
        machineSetup: true,
        protocolSetup: true,
        currentVmEnt: pagina + 1,
        vmIpCorda: loadFieldM?.ip,
        vmUsernameCorda: loadFieldM?.username,
        nameCorda: loadFieldM?.nodeName,
        portRpcAdmin: loadFielP?.portRPCAdmin,
        nodeCountry: loadFielP?.nodeCountry,
        portRpc: loadFielP?.portRPC,
        validateNotary: loadFielP?.notaryValidating,
        dbPassword: loadFielP?.dbPassword,
        rpcPassword: loadFielP?.rpcPassword,
        nameProtocol: loadFielP?.nameProtocol,
        dbUsername: loadFielP?.dbUsername,
        nodeCity: loadFielP?.nodeCity,
        portP2P: loadFielP?.portP2P,
        rpcUsername: loadFielP?.rpcUsername,
        // keyStorePassword: loadFielP?.keyStorePassword,
        // trustStorePassword: loadFielP?.trustStorePassword,
        sshdPort: loadFielP?.sshdPort,
      });
    };

    const cordaStepPage = (setupNotarie?: boolean, setupDoorman?: boolean) => {
      setPagina(pagina + 1);
      return setDeployData({
        ...deployData,
        currentVmEnt: pagina + 1,
        vmIpCorda: "",
        nameCorda: "",
        vmUsernameCorda: "",
        portRpcAdmin: "",
        portRpc: "",
        dbPassword: "",
        portP2P: "",
        rpcPassword: "",
        nameProtocol: "",
        dbUsername: "",
        rpcUsername: "",
        nodeCity: "",
        nodeCountry: "",
        // keyStorePassword: "",
        // trustStorePassword: "",
        validateNotary: false,
        sshdPort: "",
        nextStepProtocol: false,
        machineSetup: false,
        protocolSetup: false,
        setupRegularNode: false,
        setupNotarie,
        setupDoorman,
      });
    };
    if (
      deployStep === 4 &&
      !deployData.sameValues &&
      // !deployData.errorPort &&
      deployData.selectedStack === "enterprise" &&
      nextStepVM
    ) {
      setCollorButton(false);
      if (pagina < deployData.versionRegular) {
        nextStepVM = false;
        backPage ? loadNextPageData(true, false, false) : cordaStepPage();
      }

      if (
        pagina >= deployData.versionRegular &&
        pagina < deployData.versionRegular + deployData.versionNotarie
      ) {
        nextStepVM = false;
        backPage
          ? loadNextPageData(false, true, false)
          : cordaStepPage(true, false);
      } else if (
        pagina ===
        deployData.versionRegular + deployData.versionNotarie
      ) {
        nextStepVM = false;
        backPage
          ? loadNextPageData(false, false, true)
          : cordaStepPage(false, true);
      }

      if (
        pagina ===
        deployData.versionRegular + deployData.versionNotarie + 1
      ) {
        nextStepVM = false;
        setDeployData({
          ...deployData,
          vmIpCorda: "",
          nameCorda: "",
          vmUsernameCorda: "",
          portRPCAdmin: "",
          portRpc: "",
          dbPassword: "",
          rpcPassword: "",
          nameProtocol: "",
          dbUsername: "",
          rpcUsername: "",
          nodeCity: "",
          nodeCountry: "",
          sshdPort: "",
          // keyStorePassword: "",
          // validateTrustStore: "",
          setupNotarie: false,
          nextStepProtocol: false,
          machineSetup: false,
          protocolSetup: false,
          setupDoorman: true,
        });
      }
    } else {
      if (deployData.setupDoorman && deployData.protocolDoorman) {
        if (deployData.clientPort) setDeployStep(deployStep + 1);
      }
    }

    if (deployStep === 5) {
      setCollorButton(false);
      setDeployData((prevState: any) => {
        return { ...prevState, buttonColor: "#1781E2" };
      });
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 6) {
      setCollorButton(false);
      setDeployStep(0);
      setDeployData({
        token: tokenUser,
        selectedOrg: "",
        selectedEnterprise: "",
        consesus: "",
        networkName: "",
        valueNode: "0 node",
        vmIp: "",
        vmUsername: "",
        name: "",
        mnemonic: "",
        selectedStack: "",
        selectedVM: "",
        currentVm: "1 - Node",
        selectedRegion: "",
        selectedLicense: "License 1",
        licenseId: "",
        version: "4.0",
        selectedOption: "Option 2",
        selectedMode: "",
        protocolType: "",
        currentEmpty: false,
        buttonColor: "#7b8a93",
        vms: [],
        idsArray: [],
        nextLocked: true,
        progress: "creating",
        deployUUID: "",
        signedTx: "",
        fileSetup: null,
      });
    }
  };

  const onSubmitAuthorization = async () => {
    setLoading(true);
    const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
    const api = await ApiPromise.create({
      provider,
    });

    // PEGAR TODAS WALLETS
    try {
      await cryptoWaitReady();
      if (!fezLoad) {
        uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
        setFezLoad(true);
      }
    } catch (error) {
      console.log(error);
    }

    const userUUID = localStorage.getItem("userId") as string;
    const entUUID = deployData?.enterpriseId;
    let userPair: any;
    try {
      userPair = uiKeyring.getPair(
        deployData?.user?.walletAddress || user?.walletAddress
      );
    } catch (error) {
      enqueueSnackbar(textError, {
        variant: "error",
        autoHideDuration: 5000,
      });
      setTimeout(() => {
        window.open(
          `${process.env.REACT_APP_FRONT_URL_ADDRESS}/profile/wallet/recovery`,
          "_blank",
          "noopener,noreferrer"
        );
        // history("/profile/wallet/recovery");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      userPair.unlock(passwordAuthorization);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Invalid password", {
        variant: "error",
      });
      return;
    }

    const orgUUID = selectedOrg;

    const genesisHash = api.genesisHash;
    const runtimeVersion = api.runtimeVersion;

    const deployUUID = window.crypto.randomUUID();

    const nonce = await api.rpc.system.accountNextIndex(
      deployData?.user?.walletAddress
    );

    const txBaas = api.tx.multiledgersDeploy
      .requestDeployBaas(userUUID, entUUID, orgUUID, deployUUID)
      .sign(userPair, {
        genesisHash,
        blockHash: genesisHash,
        nonce,
        runtimeVersion,
      });

    await api.disconnect();

    setDeployData({ ...deployData, signedTx: txBaas, deployUUID: deployUUID });

    setDeployData((prevState: any) => {
      return { ...prevState, signedTx: txBaas, deployUUID: deployUUID };
    });

    setLoading(false);
    handleNextStep();
    handleCloseAuthoraztion();
  };

  const authorizationSign = () => {
    setOpenAuthorization(true);
  };

  const handleBackStep = () => {
    if (
      deployStep === 4 &&
      deployData.versionRegular &&
      deployData.versionNotarie
    ) {
      setCollorButton(true);
    }

    const deployStepActual = () => {
      return deployStep === 5;
    };

    // BACK BESU
    if (
      deployStep === 5 &&
      deployData.selectedStack === "development" &&
      deployData.consesus === "QBFT"
    ) {
      setBackPageBesu(true);
    } else if (
      deployStep === 4 &&
      deployData.selectedStack === "development" &&
      deployData.consesus === "QBFT"
    ) {
      setDeployStep(deployStep - 1);
      return setDeployData({
        ...deployData,
        fileSetup: null,
        currentVmEntSub: 1,
        substrate: {
          nodes: [],
        },
        besu: {
          nodes: [],
        },
        vmIp: "",
        name: "",
        vmUsername: "",
        mnemonic: "",
        nextStepProtocol: false,
        machineSetup: false,
        protocolSetup: false,
      });
    }
    // FINAL BACK BESU

    // SUBSTRATE
    if (deployStep === 4 && deployData.selectedStack === "mvp") {
      setBackPageSub(true);
      if (pageSub === 1) {
        setDeployData({ ...deployData, clearAllSub: !deployData.clearAllSub });
        setBackPageSub(false);
        setDeployStep(deployStep - 1);
        return;
      }
      const substrateMachine =
        deployData.substrate.nodes[
          deployStepActual() ? pageSub - 1 : pageSub - 2
        ].machineSetup;

      const substrateProtocol =
        deployData.substrate.nodes[
          deployStepActual() ? pageSub - 1 : pageSub - 2
        ].protocolSetup;

      setDeployData({
        ...deployData,
        currentVmEntSub: deployStepActual() ? pageSub : pageSub - 1,
        vmIp: substrateMachine.ip,
        name: substrateMachine.nodeName,
        vmUsername: substrateMachine.username,
        mnemonic: substrateProtocol.mnemonic,
        nextStepProtocol: deployStepActual() ? pagina === 3 : false,
        machineSetup: true,
        // protocolSetup: false,
      });
      setPageSub(deployStepActual() ? pageSub : pageSub - 1);
    } else {
      // corda
      setBackPage(true);
      localStorage.setItem("backUpdate", true.toString());
      setDeployData({
        ...deployData,
        buttonColor: "#1781E2",
        backUpdate: true,
      });

      if (pagina === 1) {
        setDeployData({ ...deployData, clearAll: !deployData.clearAll });
        setBackPage(false);
        setDeployStep(deployStep - 1);
        return;
      }
      // Corda back
      // Identifica notarie na volta
      const isNotarie =
        pagina - 1 > deployData.versionRegular &&
        pagina - 1 <= deployData.versionRegular + deployData.versionNotarie;

      if (isNotarie && paginaVirt >= 0 && deployStep !== 5) {
        setPaginaVirt(paginaVirt - 1);
      }

      if (pagina > 1) {
        if (deployStepActual()) {
          setDeployStep(deployStep - 1);
        }
        const lastPage =
          deployData.versionRegular + deployData.versionNotarie + 1;

        const loadFile =
          deployData.cordaCopy.generalData[
            deployStepActual() ? pagina - 1 : pagina - 2
          ].machineSetup;

        const loadFileP =
          deployData.cordaCopy.generalData[
            deployStepActual() ? pagina - 1 : pagina - 2
          ].protocolSetup;
        setDeployData({
          ...deployData,
          nextStepProtocol: deployStepActual() ? pagina === lastPage : false,
          setupNotarie: deployStepActual() ? false : isNotarie,
          setupRegularNode: pagina - 1 === 1,
          setupDoorman: deployStepActual() ? pagina === lastPage : false,
          machineSetup: true,
          currentVmEnt: deployStepActual() ? pagina : pagina - 1,
          vmIpCorda: loadFile.ip,
          vmUsernameCorda: loadFile.username,
          validateNotary: loadFileP.notaryValidating,
          nameCorda: loadFile.nodeName,
          portRpcAdmin: loadFileP.portRPCAdmin,
          portP2P: loadFileP.portP2P,
          portRpc: loadFileP.portRPC,
          dbPassword: loadFileP.dbPassword,
          rpcPassword: loadFileP.rpcPassword,
          dbUsername: loadFileP.dbUsername,
          rpcUsername: loadFileP.rpcUsername,
          nodeCity: loadFileP.nodeCity,
          nodeCountry: loadFileP.nodeCountry,
          // keyStorePassword: loadFileP?.keyStorePassword,
          // trustStorePassword: loadFileP?.trustStorePassword,
          sshdPort: loadFileP?.sshdPort,
        });
        setPagina(deployStepActual() ? pagina : pagina - 1);
      } else {
        const loadFile = deployData.cordaCopy.generalData[pagina].machineSetup;

        const loadFileP =
          deployData.cordaCopy.generalData[pagina].protocolSetup;
        setDeployData({
          ...deployData,
          nextStepProtocol: false,
          setupNotarie: false,
          setupDoorman: false,
          machineSetup: true,
          validateNotary: loadFileP.notaryValidating,
          currentVmEnt: pagina - 1,
          vmIpCorda: loadFile.ip,
          vmUsernameCorda: loadFile.username,
          nameCorda: loadFile.nodeName,
          portRpcAdmin: loadFileP.portRpcAdmin,
          portP2P: loadFileP.portP2P,
          portRpc: loadFileP.portRpc,
          dbPassword: loadFileP.dbPassword,
          rpcPassword: loadFileP.rpcPassword,
          dbUsername: loadFileP.dbUsername,
          rpcUsername: loadFileP.rpcUsername,
          nodeCity: loadFileP.nodeCity,
          nodeCountry: loadFileP.nodeCountry,
          // keyStorePassword: loadFileP?.keyStorePassword,
          // trustStorePassword: loadFileP?.trustStorePassword,
          sshdPort: loadFileP?.sshdPort,
        });
      }
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 0.5,
      left: "calc(-50%)",
      right: "calc(50%)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 8,
      border: 0,
      backgroundColor: "#A3CCF3",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: "#86B9F7",
    zIndex: 1,
    color: "#fff",
    width: 8,
    height: 8,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#1781E2",
      height: 10,
      width: 10,
      transform: "translateY(-1px)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#86B9F7",
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {};

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const backBlockButton = () => {
    if (!backPage) {
      return (
        pagina > 1 &&
        pagina < deployData.versionRegular + deployData.versionNotarie + 1
      );
    }
  };

  return (
    <Card
      sx={{
        position: "relative",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.16)",
        border: "1px solid #CBD5E1",
        borderRadius: "20px",
        width: "560px",
        height: "761px",
      }}
    >
      {deployStep > 0 && !deployData.loadVmCheck && (
        <Stepper
          alternativeLabel
          activeStep={deployStep}
          connector={<ColorlibConnector />}
          sx={{
            paddingLeft: "29px",
            paddingRight: "35px",
            paddingTop: "40px",
          }}
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <DeployBaasSteps
        step={deployStep}
        handleNextStep={handleNextStep}
        setSelectedOrg={setSelectedOrg}
        setNetworkName={setNetworkName}
        selectedOrg={selectedOrg}
        networkName={networkName}
        tokenUser={tokenUser}
      />
      {deployStep > 0 && (
        <Grid
          container
          md={12}
          sx={{
            paddingLeft: "37px",
            paddingRight: "43px",
            position: "absolute",
            bottom: "30px",
          }}
        >
          <Grid item md={6}>
            {deployStep === 6 || (
              <Button
                sx={{
                  zIndex: deployData.loadVmCheck ? "-1" : "1",
                  marginTop: "20px",
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  cursor: backBlockButton() ? "not-allowed" : "pointer",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1D1F",
                  boxShadow: "none",
                  borderRadius: "10px",
                  ":hover": {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={() =>
                  backBlockButton() ? handleBackStep() : handleBackStep()
                }
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                  color="#5F6368"
                >
                  Go Back
                </Typography>
              </Button>
            )}
          </Grid>
          <Grid item md={6}>
            <DeployAuthorization
              open={openAuthorization}
              loadingAuthorization={loading}
              onSubmitAuthorization={onSubmitAuthorization}
              handleCloseAuthorization={handleCloseAuthoraztion}
              setPasswordAuthorization={setPasswordAuthorization}
              title="CONFIRM DEPLOY"
              subText="This action will authorize the deployment of your network"
              confirm="Sign"
              cancel="Cancel"
            />
            {deployStep !== 5 && (
              <Button
                disabled={deployStep === 6 && !deployData.statusButtonDone}
                sx={{
                  zIndex: deployData.loadVmCheck ? "-1" : "1",
                  marginLeft: "38px",
                  marginTop: "20px",
                  width: "87%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: !collorButton
                    ? deployData.buttonColor
                    : "#1781E2",
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: !collorButton
                      ? deployData.buttonColor
                      : "#1781E2",
                  },
                }}
                variant="contained"
                onClick={() => handleNextStep()}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  {deployStep !== 6 ? "Next Step" : "Done"}
                </Typography>
              </Button>
            )}
            {deployStep === 5 && (
              <Button
                sx={{
                  marginLeft: "15px",
                  marginTop: "20px",
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: deployData.buttonColor,
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: deployData.buttonColor,
                  },
                }}
                variant="contained"
                onClick={authorizationSign}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  Next step
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
