import { Box, Card, Grid, Typography } from "@mui/material";
import Header from "../../components/Platform/Header/Header";
import worldMap from "~/assets/imgs/worldMap.png";
import { DeployHeader } from "../../components/Platform/DeployHeader/DeployHeader";
import { Hamburguer } from "~/assets";
import { DeployBaasWizard } from "../../components/Platform/DeployBaas/DeployBaasWizard/DeployBaasWizard";
import { WizardContext } from "../../context/wizardContext";
import { useState } from "react";

export const DeployBaas = ({
  userId,
  userName,
  tokenUser,
  user,
  thereIsOrganizationHeader,
}: any) => {
  const [deployData, setDeployData] = useState({
    user,
    token: tokenUser,
    statusButtonDone: false,
    selectedOrg: "",
    selectedEnterprise: "",
    networkName: "",
    sshdPort: "",
    clientPort: "",
    vmIpBesu: "",
    vmUsernameBesu: "",
    nameBesu: "",
    mnemonicBesu: "",
    vmIp: "",
    keyStorePassword: "",
    trustStorePassword: "",
    backUpdate: false,
    sameValues: false,
    errorPort: false,
    vmUsername: "",
    nameCorda: "",
    vmUsernameCorda: "",
    vmIpDoorman: "",
    portDoorman: "",
    valueNode: "0 node",
    name: "",
    mnemonic: "",
    consesus: "",
    selectedStack: "",
    selectedVM: "",
    currentVm: "1 - Node",
    setupRegularNode: false,
    loadVmCheck: false,
    machineSetup: false,
    nextStepProtocol: false,
    fileSetupCorda: null,
    nodeCountry: '',
    nodeCity: '',
    clearAll: false,
    clearAllSub: false,
    substrate: {
      nodes: []
    },
    besu: {
      nodes: []
    },
    corda: {
      regularNodes: [],
      notaries: [],
      doorman: [],
    },
    cordaCopy: {
      generalData: []
    },
    setupNotarie: false,
    validateNotary: false,

    setupDoorman: false,
    protocolDoorman: false,
    portRpcAdmin: "",
    portRpc: "",
    dbPassword: "",
    rpcPassword: "",
    nameProtocol: "",
    dbUsername: "",
    rpcUsername: "",
    portP2P: "",
    protocolSetup: false,
    currentVmEnt: 1,
    currentVmEntSub: 1,
    currentVmEntBesu: 1,
    selectedRegion: "",
    selectedLicense: "License 1",
    licenseId: "",
    version: "0",
    versionNotarie: 1,
    versionRegular: 1,
    selectedOption: "Option 2",
    selectedMode: "",
    protocolType: "",
    currentEmpty: false,
    buttonColor: "#7b8a93",
    vms: [],
    idsArray: [],
    nextLocked: true,
    progress: "creating",
    deployUUID: "",
    signedTx: "",
    fileSetup: null,
    totalCurrentValue: 0,
  });

  return (
    <WizardContext.Provider value={{ deployData, setDeployData }}>
      <Header
        userName={userName}
        thereIsOrganizationHeader={thereIsOrganizationHeader}
      />
      <DeployHeader />
      <Grid
        container
        md={12}
        height="75vh"
        item
        sx={{
          paddingLeft: "50px",
          paddingRight: "50px",
          paddingBottom: "57px",
          paddingTop: "58px",
        }}
      >
        <Grid
          item
          md={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "1290px",
          }}
        >
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #CBD5E1",
              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.16)",
              borderRadius: "20px",
              marginRight: "10px",
              width: "100%",
              height: "100%",
              paddingTop: "23px",
              paddingLeft: "20px",
              paddingRight: "44px",
              paddingBottom: "94.66px",
            }}
          >
            <Box>
              <Grid container item md={12}>
                <Grid item md={11}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="400"
                    lineHeight="29px"
                    letterSpacing="0em"
                    textAlign="left"
                    color="#898585"
                  >
                    Easy Deploy - Baas
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  {/* <Box sx={{ paddingLeft: "80px" }}>
                    <Hamburguer />
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{}}>
              <Box>
                <img src={worldMap} alt="" />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DeployBaasWizard tokenUser={tokenUser} user={user} />
        </Grid>
      </Grid>
    </WizardContext.Provider>
  );
};
